import { PropsWithChildren } from 'react';
import { SupportedLocaleMap } from '@zep/consts/localize';
import { useLocale } from '@zep/hooks';
import { ArrowRightIcon, PaperIcon } from '@zep/icons';
import { Carousel, CarouselContent, CarouselItem } from '@zep/ui';
import { Button } from '@zep/ui_v3';
import AutoScroll from 'embla-carousel-auto-scroll';
import Link from 'next/link';
import { Trans, useTranslation } from 'next-i18next';
import { INQUIRY_LINK } from './Cta';
export const Introduction = () => {
  return <section className="flex flex-col items-center pb-xl pt-2xl min-[751px]:pb-2xl min-[751px]:pt-3xl" data-sentry-component="Introduction" data-sentry-source-file="Introduction.tsx">
      <Title data-sentry-element="Title" data-sentry-source-file="Introduction.tsx" />
      <Buttons data-sentry-element="Buttons" data-sentry-source-file="Introduction.tsx" />
      <Pictures data-sentry-element="Pictures" data-sentry-source-file="Introduction.tsx" />
    </section>;
};
const Title = () => {
  const {
    t
  } = useTranslation(['landing', 'common']);
  return <h2 className="text-center text-heading-xl-extrabold text-default min-[751px]:text-display-sm-extrabold" data-sentry-component="Title" data-sentry-source-file="Introduction.tsx">
      <Trans t={t} i18nKey="landingV2.eduTech.title" components={{
      Strong: <StrongText />
    }} data-sentry-element="Trans" data-sentry-source-file="Introduction.tsx" />
    </h2>;
};
const StrongText = ({
  children
}: PropsWithChildren) => <strong className="text-strong" data-sentry-component="StrongText" data-sentry-source-file="Introduction.tsx">{children}</strong>;
const OVERVIEW_LINKS: SupportedLocaleMap<'ko' | 'en' | 'ja'> = {
  ko: 'https://featpaper.com/viewer/AGc8bMbfXXy3',
  en: 'https://featpaper.com/viewer/z5xEi7g7Omls',
  ja: 'https://featpaper.com/viewer/aXAabO7kCixz'
};
const Buttons = () => {
  const {
    t
  } = useTranslation(['landing', 'common']);
  const {
    locale
  } = useLocale();
  return <div className="mb-2xl mt-[24px] flex flex-col items-center gap-sm min-[751px]:mb-4xl min-[751px]:flex-row min-[751px]:gap-2xs" data-sentry-component="Buttons" data-sentry-source-file="Introduction.tsx">
      <Button variant="outlined" intent="alternative" size="lg" rounded="default" asChild={true} data-sentry-element="Button" data-sentry-source-file="Introduction.tsx">
        <Link href={OVERVIEW_LINKS[locale] ?? OVERVIEW_LINKS.en} target="_blank" rel="noopener noreferrer" data-sentry-element="Link" data-sentry-source-file="Introduction.tsx">
          <PaperIcon size="md" data-sentry-element="PaperIcon" data-sentry-source-file="Introduction.tsx" />
          {t('landing.eduTech.download')}
        </Link>
      </Button>
      <Button variant="contained" intent="gradient" size="lg" rounded="default" asChild={true} data-sentry-element="Button" data-sentry-source-file="Introduction.tsx">
        <Link href={INQUIRY_LINK[locale] ?? INQUIRY_LINK.en} target="_blank" rel="noopener noreferrer" data-sentry-element="Link" data-sentry-source-file="Introduction.tsx">
          {t('landing.eduTech.introduce')}
          <ArrowRightIcon size="md" data-sentry-element="ArrowRightIcon" data-sentry-source-file="Introduction.tsx" />
        </Link>
      </Button>
    </div>;
};
const PICTURE_LINKS = ['https://asset-zepetoful.zepeto.io/lZS2as4ZxDWZ/VowDJ3wK87durTq3vthO4Ep/NNnzq3wK87F47b10fa97e0b3f8b9767e61510a985dcurTq3xlfNNpn/1_2x.webp', 'https://asset-zepetoful.zepeto.io/lZS2as4ZxDWZ/gVFiP3wK87curTq3vsVaSLU/XImte3wK87hea574969b0ae7f9157cc91dd8b2a72a2urTq3xOGvxJW/2_2x.webp', 'https://asset-zepetoful.zepeto.io/lZS2as4ZxDWZ/FkuyJ3wK87curTq3vHNfSYn/ZVpsF3wK87h546dc50d0a69c7d9c3f4a757d1ac3c35urTq3xQZE7jK/3_2x.webp', 'https://asset-zepetoful.zepeto.io/lZS2as4ZxDWZ/CQhbi3wK87curTq3vEQywi7/3DZ1G3wK87hb97a014c80b29001fb991b5508343c24urTq3xLkOp2r/4_2x.webp', 'https://asset-zepetoful.zepeto.io/lZS2as4ZxDWZ/r6pdh3wK87durTq3vFP6lku/MSQFq3wK87Ff30c21c6c1307f43f5b553b1f8096519urTq3xnwydz1/5_2x.webp', 'https://asset-zepetoful.zepeto.io/lZS2as4ZxDWZ/JoMBk3wK87curTq3vGSruoM/sGAa83wK87hea8168179cd3a79f3dd6f0b6bebe5894urTq3xPKFpzb/6_2x.webp', 'https://asset-zepetoful.zepeto.io/lZS2as4ZxDWZ/8M3IJ3wK87durTq3vH5y0ad/9pX6f3wK87hc2608dc5c3d3cb6d8883725830e77911urTq3xNFD5Ml/7_2x.webp'];
const Pictures = () => {
  return <Carousel opts={{
    loop: true
  }} plugins={[AutoScroll({
    speed: 1,
    startDelay: 0,
    stopOnInteraction: false,
    stopOnFocusIn: false
  })]} data-sentry-element="Carousel" data-sentry-component="Pictures" data-sentry-source-file="Introduction.tsx">
      <CarouselContent className="-ml-md min-[751px]:-ml-lg" data-sentry-element="CarouselContent" data-sentry-source-file="Introduction.tsx">
        {PICTURE_LINKS.concat(PICTURE_LINKS).map((link, index) => <CarouselItem key={index} className="basis-1/7 pl-md min-[751px]:pl-lg">
            <img className="w-[176px] rounded-sm min-[751px]:w-[286px]" src={link} alt={`Edu Tech ${index}`} />
          </CarouselItem>)}
      </CarouselContent>
    </Carousel>;
};