import { PropsWithChildren, ReactNode } from 'react';
import { useIsMounted } from '@toss/react';
import { useRouter } from 'next/router';
export const NoSSR = ({
  children,
  fallback
}: PropsWithChildren<{
  fallback?: ReactNode;
}>) => {
  const isMounted = useIsMounted();
  const {
    isReady
  } = useRouter();
  if (!isMounted || !isReady) {
    return fallback ? fallback : null;
  }
  return <>{children}</>;
};