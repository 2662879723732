import React, { ComponentPropsWithRef } from 'react';
import { cn } from '@zep/utils';
import styles from './Header.module.scss';
interface Props {
  children?: React.ReactNode;
  props?: ComponentPropsWithRef<'header'>;
  className?: string;
}
export const HeaderContainer = ({
  children,
  className,
  props
}: Props) => {
  return <header className={cn(styles.header_container, 'fixed z-40 top-0 min-h-[60px] w-full bg-[#FFF] border-b border-b-[#E9EAF2]', 'm-auto flex items-center justify-between px-md py-sm md:px-[24px] lg:px-[40px]', className)} {...props} data-sentry-component="HeaderContainer" data-sentry-source-file="HeaderContainer.tsx">
      {children}
    </header>;
};