import { useEffect } from 'react';
import { useIsMounted } from '@toss/react';
interface Props {
  webFormUrl: string;
  className?: string;
}
export function SalesMapEmbed({
  webFormUrl,
  className
}: Props) {
  const mounted = useIsMounted();
  useEffect(() => {
    if (mounted) {
      const script = document.createElement('script');
      script.id = 'loadFormScript';
      script.src = 'https://salesmap.kr/web-form-loader-v3.js';
      script.async = true;
      script.onload = () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.SmFormSettings.loadForm();
      };
      document.body.appendChild(script);
    }
  }, [mounted]);
  return <div id="salesmap-web-form" data-web-form={webFormUrl} className={className} data-sentry-component="SalesMapEmbed" data-sentry-source-file="SalesMapEmbed.tsx"></div>;
}