;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"6337d9d41766495a741f15fb34307e6e52eb1e77"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from '@sentry/nextjs';

import { createBeforeSend } from './src/utils/sentry';
import baseOptions from './sentry.base.config';

Sentry.init({
  ...baseOptions,
  beforeSend: createBeforeSend,
  enableTracing: false, // 성능 하락 방지를 위해 비활성화
});
