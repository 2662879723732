import { SUPPORTED_LOCALES_OBJ } from '@zep/consts/localize';
import { GetServerSidePropsContext, GetStaticPropsContext } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

export const THIS_IS_DEFAULT_STATIC_OR_SERVER_SIDE_PROPS =
  'THIS_IS_DEFAULT_STATIC_OR_SERVER_SIDE_PROPS';

type Namespace = 'common' | 'landing';

type StaticProps = GetStaticPropsContext & {
  namespaces?: Namespace[];
};

type ServerSideProps = GetServerSidePropsContext & {
  namespaces?: Namespace[];
};

export const getDefaultServerSideProps = async ({
  locale = SUPPORTED_LOCALES_OBJ.en,
  namespaces = ['common'],
}: ServerSideProps) => {
  return {
    props: {
      ...(await serverSideTranslations(locale, namespaces)),
      [THIS_IS_DEFAULT_STATIC_OR_SERVER_SIDE_PROPS]: true,
    },
  };
};

export const getDefaultStaticProps = async ({
  locale = SUPPORTED_LOCALES_OBJ.en,
  namespaces = ['common'],
}: StaticProps) => {
  return {
    props: {
      ...(await serverSideTranslations(locale, namespaces)),
      [THIS_IS_DEFAULT_STATIC_OR_SERVER_SIDE_PROPS]: true,
    },
  };
};
