import { safeSessionStorage } from '@toss/storage';

import { GOOGLE_CLASSROOM_LANDING_PAGE_TYPE_PARAM_KEY } from './constants';
import { GoogleOAuthStorage } from './GoogleOAuthStorage';
import { GoogleClassroomPageContext, LandingPageType, UserType } from './types';

export class GoogleClassroomContextManager {
  private _sessionStorageKey = '@gc/context';

  public initializeLandingPageContext(
    searchParams = new URLSearchParams(location.search),
  ) {
    const contextFromSearchParams =
      this.getLandingPageContextFromSearchParams(searchParams);
    safeSessionStorage.set(
      this._sessionStorageKey,
      JSON.stringify(contextFromSearchParams),
    );

    return contextFromSearchParams;
  }

  /**
   * location이 변경되어도 상태가 유지되도록 sessionStorage를 이용해서 Context를 관리합니다.
   * 탭이나 브라우저를 닫으면 상태가 날아갑니다.
   */
  public getLandingPageContext(): GoogleClassroomPageContext | null {
    const context = safeSessionStorage.get(this._sessionStorageKey);
    return context == null ? null : JSON.parse(context);
  }

  private _getUserType(pageType: LandingPageType): UserType {
    switch (pageType) {
      case LandingPageType.AttachmentSetup:
      case LandingPageType.TeacherView:
      case LandingPageType.StudentWorkReview:
        return UserType.Teacher;
      case LandingPageType.StudentView:
        return UserType.Student;
      default:
        return UserType.Unknown;
    }
  }

  public getGoogleOAuthScope(): string | undefined {
    const context = this.getLandingPageContext();
    if (context == null) {
      return undefined;
    }

    switch (context.userType) {
      case UserType.Teacher:
        return 'https://www.googleapis.com/auth/classroom.addons.teacher';
      case UserType.Student:
        return 'https://www.googleapis.com/auth/classroom.addons.student';
      default:
        return undefined;
    }
  }

  public getGoogleOAuthToken(): string | null {
    return GoogleOAuthStorage.getToken();
  }

  public getLandingPageContextFromSearchParams(
    params = new URLSearchParams(window.location.search),
  ): GoogleClassroomPageContext {
    const courseId = params.get('courseId');
    const itemId = params.get('itemId');
    const itemType = params.get('itemType');
    const pageType = params.get(
      GOOGLE_CLASSROOM_LANDING_PAGE_TYPE_PARAM_KEY,
    ) as LandingPageType;
    const userType = this._getUserType(pageType);

    switch (pageType) {
      case LandingPageType.AttachmentSetup: {
        const addOnToken = params.get('addOnToken');

        if (!courseId || !itemId || !itemType || !addOnToken) {
          throw new MissingRequiredParametersError();
        }

        return {
          pageType,
          userType,
          params: {
            courseId,
            itemId,
            itemType,
            addOnToken,
          },
        };
      }
      case LandingPageType.TeacherView: {
        const attachmentId = params.get('attachmentId');
        if (!courseId || !itemId || !itemType || !attachmentId) {
          throw new MissingRequiredParametersError();
        }

        return {
          pageType,
          userType,
          params: {
            courseId,
            itemId,
            itemType,
            attachmentId,
          },
        };
      }
      case LandingPageType.StudentView: {
        const attachmentId = params.get('attachmentId');
        if (!courseId || !itemId || !itemType || !attachmentId) {
          throw new MissingRequiredParametersError();
        }

        return {
          pageType,
          userType,
          params: {
            courseId,
            itemId,
            itemType,
            attachmentId,
          },
        };
      }
      case LandingPageType.StudentWorkReview: {
        const attachmentId = params.get('attachmentId');
        const submissionId = params.get('submissionId');

        if (
          !courseId ||
          !itemId ||
          !itemType ||
          !attachmentId ||
          !submissionId
        ) {
          throw new MissingRequiredParametersError();
        }

        return {
          pageType,
          userType,
          params: {
            courseId,
            itemId,
            itemType,
            attachmentId,
            submissionId,
          },
        };
      }
      default:
        return {
          pageType,
          userType,
          params: {},
        };
    }
  }
}

export const googleClassroomContextManager =
  new GoogleClassroomContextManager();

export class MissingRequiredParametersError extends Error {
  constructor(message: string = 'Missing required parameters.') {
    super(message);
    this.name = 'MissingRequiredParametersError';
  }
}
