import { ComponentPropsWithoutRef, useEffect } from 'react';
import * as Sentry from '@sentry/nextjs';
import { useQueryClient } from '@tanstack/react-query';
import { isIgnoreError } from '@zep/api/apiUtil';
import { useEffectOnce } from '@zep/hooks';
import { ExclamationmarkSmallTriangleFillIcon } from '@zep/icons';
import { Button } from '@zep/ui';
import { ICON_COLOR } from '@zep/ui/ui-tokens/color';
import { cn } from '@zep/utils';
import { Trans, useTranslation } from 'next-i18next';
import useLocalStorageState from 'use-local-storage-state';

//www.figma.com/design/2C2ZPxnoDDelvM5zM863RQ/%F0%9F%8F%8C%EF%B8%8F%E2%80%8D%E2%99%82%EF%B8%8F-Quiz-WEB?node-id=8898-41795&m=dev
// 에러 기본 파운더리
export const ErrorBoundaryFallback = (props: ComponentPropsWithoutRef<'div'> & {
  size?: 'sm' | 'md' | 'lg';
} & {
  error?: Error;
  reset?: () => void;
}) => {
  const {
    className,
    size = 'lg',
    error,
    reset: _,
    ...rest
  } = props;
  const {
    t,
    ready
  } = useTranslation();
  const client = useQueryClient();
  const [level] = useLocalStorageState('log_level');
  const _isIgnoreError = isIgnoreError(error);
  useEffectOnce(() => {
    client.invalidateQueries();
  });
  useEffect(() => {
    if (error && !_isIgnoreError) {
      Sentry.captureException(error);
    }
  }, [error, _isIgnoreError]);
  if (!ready) return null;

  /** 해당에러들은 useApiError 에서 처리되므로 여기서 처리하지 않는다. */
  if (_isIgnoreError) {
    return null;
  }
  const notLoadContent = t('common.error.notLoadContent', '요청하신 내용을 불러오지 못했습니다');
  if (size === 'sm') return <ErrorBoundaryFallbackSmall {...props} />;
  if (size === 'md') return <ErrorBoundaryFallbackMedium {...props}>
        {notLoadContent}
      </ErrorBoundaryFallbackMedium>;
  return <div className={cn('flex w-full flex-col items-center gap-md', className)} {...rest} data-sentry-component="ErrorBoundaryFallback" data-sentry-source-file="ErrorBoundaryFallback.tsx">
      <ExclamationmarkSmallTriangleFillIcon width={48} height={48} fill={ICON_COLOR.disabled} data-sentry-element="ExclamationmarkSmallTriangleFillIcon" data-sentry-source-file="ErrorBoundaryFallback.tsx" />
      <span className={'text-heading-lg-extrabold text-default'}>
        {notLoadContent}
      </span>
      <span className={'text-center text-body-md-medium text-neutral'}>
        {/* // 잠시 후 다시 접속을 시도해 주세요.\n문제가 지속될 경우, <Link>[문의하기]</Link>를 눌러 버그를 제보해 주세요. */}
        <Trans i18nKey="common.errorBoundary.content" components={{
        Link: <a href={t('common.errorBoundary.contactLink')} target="_blank" className="text-body-md-bold text-link" />
      }} data-sentry-element="Trans" data-sentry-source-file="ErrorBoundaryFallback.tsx" />
      </span>

      {Number(level) > 0 && <div className={'w-[80%] break-words text-body-lg-medium'}>
          {JSON.stringify(error)}
        </div>}
      <Button className={'mt-xs'} size={'lg'} onClick={() => window.location.reload()} data-sentry-element="Button" data-sentry-source-file="ErrorBoundaryFallback.tsx">
        {t('common.error.reload', '새로고침')}
      </Button>
    </div>;
};
const ErrorBoundaryFallbackMedium = (props: ComponentPropsWithoutRef<'div'>) => {
  const {
    className,
    children,
    ...rest
  } = props;
  return <div className={'flex w-full justify-center'} data-sentry-component="ErrorBoundaryFallbackMedium" data-sentry-source-file="ErrorBoundaryFallback.tsx">
      <div className={cn('w-full max-w-[80%] flex justify-center items-center py-md  bg-neutral rounded-md gap-[10px]', 'text-alternative text-body-sm-bold', className)} {...rest}>
        <ExclamationmarkSmallTriangleFillIcon size={'lg'} fill={ICON_COLOR.disabled} data-sentry-element="ExclamationmarkSmallTriangleFillIcon" data-sentry-source-file="ErrorBoundaryFallback.tsx" />
        {children}
      </div>
    </div>;
};
const ErrorBoundaryFallbackSmall = (props: ComponentPropsWithoutRef<'div'>) => {
  const {
    className,
    ...rest
  } = props;
  return <div className={cn('w-[94px] p-md flex justify-center items-center rounded-md bg-neutral', className)} {...rest} data-sentry-component="ErrorBoundaryFallbackSmall" data-sentry-source-file="ErrorBoundaryFallback.tsx">
      <ExclamationmarkSmallTriangleFillIcon size={'lg'} fill={ICON_COLOR.disabled} data-sentry-element="ExclamationmarkSmallTriangleFillIcon" data-sentry-source-file="ErrorBoundaryFallback.tsx" />
    </div>;
};