import { SupportedLocaleMap } from '@zep/consts/localize';
import { useLocale } from '@zep/hooks';
import { useTranslation } from 'next-i18next';
import { FunctionCard, FunctionCards } from './FunctionCards';
import { ProChip } from './ProChip';
export const Ai = () => {
  const {
    t
  } = useTranslation(['landing', 'common']);
  return <section className="px-[16px] py-2xl min-[751px]:px-[24px] min-[1025px]:px-[40px] min-[1025px]:py-3xl" data-sentry-component="Ai" data-sentry-source-file="Ai.tsx">
      <div className="mx-auto flex w-full max-w-[1280px] flex-col items-center gap-lg min-[751px]:items-stretch min-[751px]:gap-2xl min-[1025px]:gap-3xl">
        <div className="flex flex-col-reverse items-center gap-[10px] px-[16px] min-[751px]:flex-row">
          <h2 className="text-center text-display-sm-extrabold text-default min-[751px]:whitespace-normal min-[751px]:text-left">
            {t('landing.aiFeatures.title')}
          </h2>
          <ProChip data-sentry-element="ProChip" data-sentry-source-file="Ai.tsx" />
        </div>
        <FunctionCards topElement={<Function1 />} bottomLeftElement={<Function2 />} bottomRightElement={<Function3 />} data-sentry-element="FunctionCards" data-sentry-source-file="Ai.tsx" />
      </div>
    </section>;
};
const FUNCTION_1_IMAGE_LINKS: SupportedLocaleMap<'ko' | 'en' | 'ja'> = {
  ko: 'https://asset-zepetoful.zepeto.io/lZS2as4ZxDWZ/ZepcL3wK87cuxi32WYZ0MrI/IsnXs3wK87F65de843c53fe1fa9452fe1a2fe62f5cbuxi32Z245w6S/2_1_image_kr.webp',
  en: 'https://asset-zepetoful.zepeto.io/lZS2as4ZxDWZ/u6caT3wK87cuxhZh75RAJM6/olKTK3wK87h12287f9ddba776e6244f09f857a49a74uxhZh9jzvHcl/2_1_image_en.webp',
  ja: 'https://asset-zepetoful.zepeto.io/lZS2as4ZxDWZ/aejkb3wK87cuxhZh76MMixC/I5dFn3wK87F1d79c63e4c1d697af8ed946d635241c4uxhZh9nV1oj4/2_1_image_jp.webp'
};
const Function1 = () => {
  const {
    locale
  } = useLocale();
  const {
    t
  } = useTranslation(['landing', 'common']);
  return <FunctionCard.HorizontalWrapper className="bg-green-50" data-sentry-element="unknown" data-sentry-component="Function1" data-sentry-source-file="Ai.tsx">
      <div className="flex-1">
        <div className="flex size-full flex-col items-center gap-sm px-lg py-xl min-[751px]:items-stretch min-[751px]:gap-lg min-[751px]:p-2xl min-[751px]:pr-0 min-[1025px]:p-3xl min-[1025px]:pr-0">
          <div className="flex flex-col items-center gap-md min-[751px]:items-stretch">
            <FunctionCard.Title className="text-mint-900" data-sentry-element="unknown" data-sentry-source-file="Ai.tsx">
              {t('landing.aiFeature1.title')}
            </FunctionCard.Title>
          </div>
          <FunctionCard.Description data-sentry-element="unknown" data-sentry-source-file="Ai.tsx">
            {t('landing.aiFeature1.content')}
          </FunctionCard.Description>
        </div>
      </div>

      <div className="flex-1 min-[751px]:h-[310px] min-[1025px]:h-[374px]">
        <img src={FUNCTION_1_IMAGE_LINKS[locale] ?? FUNCTION_1_IMAGE_LINKS.en} alt="unlimited AI quiz" className="size-full min-[751px]:object-cover min-[751px]:object-left" />
      </div>
    </FunctionCard.HorizontalWrapper>;
};
const FUNCTION_2_IMAGE_LINKS: SupportedLocaleMap<'ko' | 'en' | 'ja'> = {
  ko: 'https://asset-zepetoful.zepeto.io/lZS2as4ZxDWZ/BfgPf3wK87duxi32WXBRKaC/UgY0A3wK87F42083a0032476df309b7424693bc02cbuxi32Z3Et76T/2_2_image_kr.webp',
  en: 'https://asset-zepetoful.zepeto.io/lZS2as4ZxDWZ/mEN323wK87duxtZajZDrUFq/cO1u33wK87hb20837c26e273b9fc9f692e54d723fbeuxtZbvQ4ho5F/2_2_image_en.webp',
  ja: 'https://asset-zepetoful.zepeto.io/lZS2as4ZxDWZ/Jq6qy3wK87duxhZh74HE410/owPIM3wK87h5dda54997302ef414271e2a88267e2a2uxhZh8XgX51e/2_2_image_jp.webp'
};
const Function2 = () => {
  const {
    locale
  } = useLocale();
  const {
    t
  } = useTranslation(['landing', 'common']);
  return <FunctionCard.VerticalWrapper className="bg-green-100" data-sentry-element="unknown" data-sentry-component="Function2" data-sentry-source-file="Ai.tsx">
      <div className="flex flex-col items-center gap-sm px-lg py-xl min-[751px]:items-stretch min-[751px]:gap-lg min-[751px]:p-2xl min-[751px]:pb-0 min-[1025px]:p-3xl min-[1025px]:pb-0">
        <FunctionCard.Title className="text-mint-900" data-sentry-element="unknown" data-sentry-source-file="Ai.tsx">
          {t('landing.aiFeature2.title')}
        </FunctionCard.Title>
        <FunctionCard.Description data-sentry-element="unknown" data-sentry-source-file="Ai.tsx">
          {t('landing.aiFeature2.content')}
        </FunctionCard.Description>
      </div>

      <img src={FUNCTION_2_IMAGE_LINKS[locale] ?? FUNCTION_2_IMAGE_LINKS.en} alt="easy and fast AI quiz" />
    </FunctionCard.VerticalWrapper>;
};
const FUNCTION_3_IMAGE_LINKS: SupportedLocaleMap<'ko' | 'en' | 'ja'> = {
  ko: 'https://asset-zepetoful.zepeto.io/lZS2as4ZxDWZ/PLEc13wK87duxhZh79H7ZDm/O0RHa3wK87Fc21f945b619f1aa1a01b85890161d22fuxhZh9oc7S7x/2_3_image_kr.webp',
  en: 'https://asset-zepetoful.zepeto.io/lZS2as4ZxDWZ/NqYyv3wK87cuxhZh75QDLQa/yMTEk3wK87hafa7d284d89ffa5a52e2bca84151c4aeuxhZh99EsNer/2_3_image_en.webp',
  ja: 'https://asset-zepetoful.zepeto.io/lZS2as4ZxDWZ/hCWoz3wK87duxhZh748eSeJ/Ludin3wK87h2cee0b043a0fba97f9228f3338abba28uxhZh9q5LuKT/2_3_image_jp.webp'
};
const Function3 = () => {
  const {
    locale
  } = useLocale();
  const {
    t
  } = useTranslation(['landing', 'common']);
  return <FunctionCard.VerticalWrapper className="bg-[#F0F7F1]" data-sentry-element="unknown" data-sentry-component="Function3" data-sentry-source-file="Ai.tsx">
      <div className="flex flex-col items-center gap-sm px-lg py-xl min-[751px]:items-stretch min-[751px]:gap-lg min-[751px]:p-2xl min-[751px]:pb-0 min-[1025px]:p-3xl min-[1025px]:pb-0">
        <FunctionCard.Title className="text-mint-900" data-sentry-element="unknown" data-sentry-source-file="Ai.tsx">
          {t('landing.aiFeature3.title')}
        </FunctionCard.Title>
        <FunctionCard.Description data-sentry-element="unknown" data-sentry-source-file="Ai.tsx">
          {t('landing.aiFeature3.content')}
        </FunctionCard.Description>
      </div>

      <img src={FUNCTION_3_IMAGE_LINKS[locale] ?? FUNCTION_3_IMAGE_LINKS.en} alt="AI quiz using uploaded files" />
    </FunctionCard.VerticalWrapper>;
};