import { cn } from '@zep/utils';
interface Props {
  title: string;
  description: string;
  icon: JSX.Element;
  progressRatio: number;
  isSelected: boolean;
  onClick?: () => void;
}
export const VideoInfoCard = ({
  title,
  description,
  icon,
  progressRatio,
  isSelected,
  onClick
}: Props) => {
  return <li className={cn('w-full cursor-pointer rounded-md overflow-hidden', isSelected ? 'bg-default shadow-sm' : 'bg-opacity-white-6 hover:bg-opacity-white-10')} onClick={onClick} data-sentry-component="VideoInfoCard" data-sentry-source-file="VideoInfoCard.tsx">
      <div className={cn('bg-gray-200 h-[4px]', !isSelected && 'invisible')}>
        {isSelected && <div className="size-full translate-x-[-100%] bg-primary transition duration-300 ease-linear" style={{
        transform: `translateX(${-100 + 100 * progressRatio}%)`
      }} />}
      </div>

      <div className="flex items-center justify-center gap-xs px-lg py-md min-[751px]:justify-start min-[751px]:py-lg min-[1025px]:items-start min-[1025px]:px-xl">
        <div className={cn('py-4xs min-[1025px]:py-[4px]', isSelected ? 'fill-strong' : 'fill-alternative')}>
          {icon}
        </div>
        <div className="flex flex-col gap-3xs">
          <h5 className={cn('text-body-lg-bold min-[751px]:text-heading-sm-bold min-[1025px]:text-heading-lg-extrabold', isSelected ? 'text-strong' : 'text-assistive')}>
            {title}
          </h5>
          <p className={cn('hidden min-[1025px]:block text-body-lg-medium', isSelected ? 'text-neutral' : 'text-assistive')}>
            {description}
          </p>
        </div>
      </div>
    </li>;
};