import { isServer } from '@toss/utils';
import { setApiErrorLogger } from '@zep/api/apiUtil';
import { isDevelopment } from '@zep/consts/env';
import { getSafetyApiToken } from '@zep/utils/getSafetyApiToken';
import axios, { AxiosInstance, AxiosPromise, AxiosRequestConfig } from 'axios';
import { requestLogger } from 'axios-logger';

import { UnauthenticatedApiRoute } from './apiRoute.const';

class ApiClient {
  private static instance: ApiClient;
  private readonly axiosInstance: AxiosInstance;
  private interceptorManager: InterceptorManager;

  private constructor(config: AxiosRequestConfig) {
    this.axiosInstance = axios.create(config);
    this.interceptorManager = new InterceptorManager(this.axiosInstance);
    this.interceptorManager.setupInterceptors();
  }

  public static getInstance(config: AxiosRequestConfig): ApiClient {
    if (!ApiClient.instance) {
      ApiClient.instance = new ApiClient(config);
    }
    return ApiClient.instance;
  }

  public setToken(token: string): void {
    if (!token) {
      delete this.axiosInstance.defaults.headers['Authorization'];
      return;
    }
    this.axiosInstance.defaults.headers['Authorization'] = `Bearer ${token}`;
  }
  public get<T>(url: string, config?: AxiosRequestConfig): AxiosPromise<T> {
    return this.axiosInstance.get(url, config);
  }

  public post<T = BaseResponse>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig,
  ): AxiosPromise<T> {
    return this.axiosInstance.post(url, data, config);
  }

  public patch<T = BaseResponse>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig,
  ): AxiosPromise<T> {
    return this.axiosInstance.patch(url, data, config);
  }

  public delete<T = BaseResponse>(
    url: string,
    config?: AxiosRequestConfig,
  ): AxiosPromise<T> {
    return this.axiosInstance.delete(url, config);
  }

  public postForm<T = BaseResponse>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig,
  ): AxiosPromise<T> {
    return this.axiosInstance.postForm(url, data, config);
  }
}

export const axiosConfig: AxiosRequestConfig = {
  baseURL: `${process.env.NEXT_PUBLIC_SERVER_BASEURL}/api/`,
  headers: {
    'Content-Type': 'application/json',
    ...(getSafetyApiToken()?.length > 0 && {
      Authorization: `Bearer ${getSafetyApiToken()}`,
    }),
  },
  timeout: 1000 * 15, // 15초 retry 는 react-query 레벨로 이관
  timeoutErrorMessage: 'API Request Real Timeout ',
};

class InterceptorManager {
  constructor(private axiosInstance: AxiosInstance) {}

  public setupInterceptors() {
    this.axiosInstance.interceptors.request.use(
      config => {
        if (UnauthenticatedApiRoute.includes(config.url || '')) {
          delete config.headers['Authorization'];
        }
        if (isServer() && isDevelopment) {
          return requestLogger(config, {
            params: true,
            data: true,
          });
        }

        return config;
      },
      error => Promise.reject(error),
    );

    this.axiosInstance.interceptors.response.use(
      response => {
        if (response.status === 401) {
          throw response.data;
        }
        return response;
      },
      error => {
        setApiErrorLogger(error);
        return Promise.reject(error);
      },
    );
  }
}

/**
 *  httpClient 로 통일예정(orval 사용)
 *  @deprecated
 * */
export const apiClient = ApiClient.getInstance(axiosConfig);

type BaseResponse<T = unknown> = { data: { status: string; data: T } };
