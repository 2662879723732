import { isSafari } from 'react-device-detect';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useInputState } from '@toss/react';
import { zepAnalytics } from '@zep/utils/analytics';
import { useTranslation } from 'next-i18next';
import { useEnterCode } from '../../api/quiz';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogPanel, DialogTitle } from '../ui/dialog';
import { TextField } from '../ui/TextField';
function _CodeEnter() {
  const modal = useModal();
  const {
    t
  } = useTranslation();
  const [inputValue, setInputValue] = useInputState('');
  const {
    mutateAsync
  } = useEnterCode();
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!inputValue?.trim()) {
      alert(t('codeEnterModal.error.input'));
      return;
    }
    try {
      const {
        data,
        status
      } = await mutateAsync({
        enterCode: inputValue
      });
      if (status === 'FAILED') {
        alert(t('codeEnterModal.error.notFound'));
        return;
      }
      zepAnalytics.track('code_complete');
      zepAnalytics.track('enter_play', {
        location: 'enter_code',
        spaceId: data.spaceHashId
      });
      if (isSafari) {
        location.href = `/play/${data.entryMapHashId}`;
      } else {
        window.open(`/play/${data.entryMapHashId}`, '_blank');
      }
    } catch (e) {
      console.error(e);
    }
  };
  return <Dialog onClick={modal.remove} data-sentry-element="Dialog" data-sentry-component="_CodeEnter" data-sentry-source-file="CodeEnter.tsx">
      <DialogPanel className="max-w-[720px]" data-sentry-element="DialogPanel" data-sentry-source-file="CodeEnter.tsx">
        <DialogHeader onClose={modal.remove} data-sentry-element="DialogHeader" data-sentry-source-file="CodeEnter.tsx">
          <DialogTitle data-sentry-element="DialogTitle" data-sentry-source-file="CodeEnter.tsx">{t('codeEnterModal.title')}</DialogTitle>
        </DialogHeader>
        <form onSubmit={handleSubmit}>
          <DialogContent data-sentry-element="DialogContent" data-sentry-source-file="CodeEnter.tsx">
            <TextField isBig autoFocus maxLength={10} placeholder={t('codeEnterModal.placeholder')} value={inputValue} onChange={setInputValue} type="number" className="w-full [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none" data-sentry-element="TextField" data-sentry-source-file="CodeEnter.tsx" />
          </DialogContent>
          <DialogFooter className="flex justify-end pt-0" data-sentry-element="DialogFooter" data-sentry-source-file="CodeEnter.tsx">
            <button type="submit" className="min-w-[140px] rounded-[6px] bg-primary px-sm py-xs text-body-lg font-semibold leading-[1.4] text-white">
              {t('global.enter.button')}
            </button>
          </DialogFooter>
        </form>
      </DialogPanel>
    </Dialog>;
}
export const CodeEnter = NiceModal.create(_CodeEnter);