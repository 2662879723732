import { PropsWithChildren, useEffect, useState } from 'react';
import * as channelService from '@channel.io/channel-web-sdk-loader';
import { useRouter } from 'next/router';
enum LoadStep {
  BeforeLoad,
  Load,
  Boot,
}
function isDisabledPathname(pathname: string) {
  const DISABLED_PATHNAMES = ['/quiz/[id]', '/play/[entryMapHashId]'];
  if (DISABLED_PATHNAMES.includes(pathname)) {
    return true;
  }
  if (pathname.startsWith('/admin')) {
    return true;
  }
  return false;
}
export const ChannelTalk = ({
  children
}: PropsWithChildren) => {
  const {
    isReady,
    pathname
  } = useRouter();
  const shouldDisable = isDisabledPathname(pathname);
  const [loadStatus, setIsLoaded] = useState<LoadStep>(LoadStep.BeforeLoad);
  useEffect(() => {
    if (isReady) {
      if (!shouldDisable && loadStatus === LoadStep.BeforeLoad) {
        channelService.loadScript();
        setIsLoaded(LoadStep.Load);
      }
    }
  }, [isReady, shouldDisable, loadStatus]);
  useEffect(() => {
    if (loadStatus === LoadStep.Load) {
      channelService.boot({
        pluginKey: '26103066-96bf-40fa-8e6c-fc7afba2378f'
      });
      setIsLoaded(LoadStep.Boot);
    }
  }, [loadStatus]);
  useEffect(() => {
    if (loadStatus === LoadStep.BeforeLoad) return;
    if (shouldDisable) {
      channelService.hideChannelButton();
    } else {
      channelService.showChannelButton();
    }
  }, [loadStatus, shouldDisable]);
  useEffect(() => {
    return () => {
      if (loadStatus === LoadStep.Boot) {
        channelService.shutdown();
      }
    };
  }, [loadStatus]);
  return children;
};