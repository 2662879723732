import type { SVGProps } from 'react';
export const KeyboardUpColor = (props: SVGProps<SVGSVGElement>) => <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props} data-sentry-element="svg" data-sentry-component="KeyboardUpColor" data-sentry-source-file="KeyboardUpColor.tsx">
    <path fill="#E9EAF2" d="M2 5.74a2 2 0 0 1 .481-1.302l1.92-2.24A2 2 0 0 1 5.92 1.5h11.424a2 2 0 0 1 1.338.513l2.656 2.391A2 2 0 0 1 22 5.891V20.5a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2z" data-sentry-element="path" data-sentry-source-file="KeyboardUpColor.tsx" />
    <path fill="url(#Keyboard-up-color_svg__a)" d="M6 5V3.5a2 2 0 0 1 2-2h10.08a2 2 0 0 1 1.519.698l1.92 2.24A2 2 0 0 1 22 5.74V20.5a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2z" data-sentry-element="path" data-sentry-source-file="KeyboardUpColor.tsx" />
    <path fill="url(#Keyboard-up-color_svg__b)" d="M4 22.5h16c.693 0 1.303-.353 1.662-.888l-1.884-4.946H4.222l-1.884 4.946c.359.535.97.887 1.662.887" data-sentry-element="path" data-sentry-source-file="KeyboardUpColor.tsx" />
    <path fill="#fff" d="M4 3.5a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2z" data-sentry-element="path" data-sentry-source-file="KeyboardUpColor.tsx" />
    <path fill="url(#Keyboard-up-color_svg__c)" fillRule="evenodd" d="M18 2.5H6a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-12a1 1 0 0 0-1-1m-12-1a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-12a2 2 0 0 0-2-2z" clipRule="evenodd" data-sentry-element="path" data-sentry-source-file="KeyboardUpColor.tsx" />
    <path fill={props.fill || `currentColor`} stroke="#E9EAF2" strokeMiterlimit={10} d="M16.95 10.86a1 1 0 0 1-.767 1.64H8.318a1 1 0 0 1-.769-1.64l3.933-4.72a1 1 0 0 1 1.536 0z" data-sentry-element="path" data-sentry-source-file="KeyboardUpColor.tsx" />
    <defs data-sentry-element="defs" data-sentry-source-file="KeyboardUpColor.tsx">
      <linearGradient id="Keyboard-up-color_svg__a" x1={19.5} x2={22} y1={9.5} y2={9.5} gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="KeyboardUpColor.tsx">
        <stop stopColor="#B8BECD" data-sentry-element="stop" data-sentry-source-file="KeyboardUpColor.tsx" />
        <stop offset={1} stopColor="#D5D9E0" data-sentry-element="stop" data-sentry-source-file="KeyboardUpColor.tsx" />
      </linearGradient>
      <linearGradient id="Keyboard-up-color_svg__b" x1={12} x2={12} y1={16.666} y2={22.499} gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="KeyboardUpColor.tsx">
        <stop stopColor="#A5ACB8" data-sentry-element="stop" data-sentry-source-file="KeyboardUpColor.tsx" />
        <stop offset={1} stopColor="#BEC3CC" data-sentry-element="stop" data-sentry-source-file="KeyboardUpColor.tsx" />
      </linearGradient>
      <linearGradient id="Keyboard-up-color_svg__c" x1={12} x2={12} y1={1.5} y2={17.5} gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="KeyboardUpColor.tsx">
        <stop stopColor="#DDDFEA" data-sentry-element="stop" data-sentry-source-file="KeyboardUpColor.tsx" />
        <stop offset={1} stopColor="#EAECF5" data-sentry-element="stop" data-sentry-source-file="KeyboardUpColor.tsx" />
      </linearGradient>
    </defs>
  </svg>;