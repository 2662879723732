import { Logger, LogOptions } from './loggerType';

type LoggerConstructor = new (...args: any[]) => Logger;

type ZepLogOptions = LogOptions & {
  useConsole?: boolean;
  excludedLoggers?: LoggerConstructor[];
};

/**
 * Usage:
 *
 * Example 1: Basic usage
 * const logger = new ZEPLogger({
 *   initialState: { userId: '12345' },
 *   loggers: [consoleLogger, serverLogger],
 * });
 *
 * logger.log('User logged in');
 *
 * Example 2: Using optional parameters
 * logger.log('User updated profile', {
 *   optionalParams: { profileSection: 'bio' },
 *   useConsole: true,
 * });
 *
 * Example 3: Resetting state
 * logger.setState({ sessionId: 'abcde' });
 * logger.resetState();
 *
 * Example 4: Excluding specific loggers
 * logger.log('User logged out', {
 *   excludedLoggers: [ZettaLogger],
 * });
 */
export class ZEPLogger {
  public name = 'ZEPLogger';
  private _initialState: object;
  private _state: object;
  private _loggers: Logger[];

  constructor({
    initialState = {},
    loggers = [],
  }: {
    initialState?: object;
    loggers?: Logger[];
  }) {
    this._initialState = initialState;
    this._state = initialState;
    this._loggers = loggers;
  }

  setState(state: object) {
    this._state = { ...this._state, ...state };
  }

  resetState() {
    this._state = this._initialState;
  }

  /**
   * @param message - 로그 메시지
   * @param options.optionalParams - 메시지와 함께 출력할 정보
   * @param options.shouldDebounce - 서버에 로그를 묶어서 전송할지 여부. default: true
   * @param options.useConsole - 콘솔에 로그를 출력할지 여부. default: false
   * @param options.excludedLoggers - 로그를 전송하지 않을 Logger 목록. default: []
   */
  public log(message: string, options?: ZepLogOptions): void {
    const {
      optionalParams = {},
      shouldDebounce = true,
      useConsole = false,
      excludedLoggers = [],
    } = options ?? {};
    const paramsWithState = { ...this._state, ...optionalParams };

    if (useConsole) {
      console.log(message, paramsWithState);
    }

    for (const logger of this._loggers) {
      const shouldIgnore = this._shouldIgnore(logger, { excludedLoggers });
      if (!shouldIgnore) {
        logger.log(message, {
          optionalParams: paramsWithState,
          shouldDebounce,
        });
      }
    }
  }

  private _shouldIgnore(
    logger: Logger,
    options: {
      excludedLoggers: LoggerConstructor[];
    },
  ): boolean {
    if (
      options.excludedLoggers.some(
        excludedLogger => logger instanceof excludedLogger,
      )
    ) {
      return true;
    }

    return false;
  }
}
