import { PropsWithChildren, ReactElement } from 'react';
import { cn } from '@zep/utils';
interface FunctionCardsProps {
  topElement: ReactElement;
  bottomLeftElement: ReactElement;
  bottomRightElement: ReactElement;
}
export const FunctionCards = ({
  topElement,
  bottomLeftElement,
  bottomRightElement
}: FunctionCardsProps) => <div className="grid w-full grid-cols-1 justify-items-center gap-md min-[751px]:grid-cols-2 min-[751px]:gap-xl min-[1025px]:gap-2xl" data-sentry-component="FunctionCards" data-sentry-source-file="FunctionCards.tsx">
    <div className="col-span-1 min-[751px]:col-span-2 min-[751px]:w-full">
      {topElement}
    </div>
    {bottomLeftElement}
    {bottomRightElement}
  </div>;
interface FunctionCardProps {
  className?: string;
}
const HorizontalWrapper = ({
  className,
  children
}: PropsWithChildren<FunctionCardProps>) => <article className={cn('border-opacity-black-2 flex w-full max-w-[500px] flex-col overflow-hidden rounded-lg border min-[751px]:max-w-none min-[751px]:flex-row', className)} data-sentry-component="HorizontalWrapper" data-sentry-source-file="FunctionCards.tsx">
    {children}
  </article>;
const VerticalWrapper = ({
  className,
  children
}: PropsWithChildren<FunctionCardProps>) => <article className={cn('border-opacity-black-2 flex w-full max-w-[500px] flex-col justify-between overflow-hidden rounded-lg border min-[751px]:h-[420px] min-[751px]:max-w-none min-[1025px]:h-[480px]', className)} data-sentry-component="VerticalWrapper" data-sentry-source-file="FunctionCards.tsx">
    {children}
  </article>;
const Title = ({
  className,
  children
}: PropsWithChildren<FunctionCardProps>) => <h3 className={cn('text-center text-heading-lg-extrabold min-[750px]:text-heading-xl-extrabold min-[751px]:text-left', className)} data-sentry-component="Title" data-sentry-source-file="FunctionCards.tsx">
    {children}
  </h3>;
const Description = ({
  className,
  children
}: PropsWithChildren<FunctionCardProps>) => <p className={cn('text-center text-body-lg-bold text-opacity-black-9 min-[751px]:text-left min-[751px]:text-heading-sm-bold', className)} data-sentry-component="Description" data-sentry-source-file="FunctionCards.tsx">
    {children}
  </p>;
export const FunctionCard = {
  HorizontalWrapper: HorizontalWrapper,
  VerticalWrapper: VerticalWrapper,
  Title,
  Description
};