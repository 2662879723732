/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * ZEP QUIZ
 * Zep Quiz API
 * OpenAPI spec version: 0.0.1
 */
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query';
import { useMutation, useQuery, useSuspenseQuery } from '@tanstack/react-query';

import type { BodyType, ErrorType } from '../../httpClient';
import { httpsInstance } from '../../httpClient';
import type {
  BaseResponseListQuestionDto,
  BaseResponseQuizAuthorRes,
  BaseResponseQuizDetailsRes,
  BaseResponseQuizEntryMapRes,
  BaseResponseQuizSetDetailsRes,
  BaseResponseUploadImageRes,
  CreateQuizSetReq,
  DeleteQuizSetReq,
  GenerateQuizzesByAIReq,
  GetAllQuizzes1Params,
  GetQuizSetByEntryMapParams,
  GetQuizSetByEntryMapV2Params,
  GetQuizSetDetailsParams,
  GetQuizSetDetailsV2Params,
  QuizSetEnterReq,
  SearchQuizSetReq,
  SearchResponseListSearchQuizResV1,
  SuccessResponse,
  UpdatePlayCountParams,
  UpdatePlayCountV2Params,
  UpdateQuizSetReq,
  UploadImageBody,
  UploadImageV2Body,
  VerifyAuthorParams,
  VerifyAuthorV2Params,
} from '.././_model';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const uploadImageV2 = (
  uploadImageV2Body: BodyType<UploadImageV2Body>,
  options?: SecondParameter<typeof httpsInstance>,
) => {
  return httpsInstance<BaseResponseUploadImageRes>(
    {
      url: `/v2/quizzes/upload/image`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: uploadImageV2Body,
    },
    options,
  );
};

export const getUploadImageV2MutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof uploadImageV2>>,
    TError,
    { data: BodyType<UploadImageV2Body> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof uploadImageV2>>,
  TError,
  { data: BodyType<UploadImageV2Body> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof uploadImageV2>>,
    { data: BodyType<UploadImageV2Body> }
  > = props => {
    const { data } = props ?? {};

    return uploadImageV2(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UploadImageV2MutationResult = NonNullable<
  Awaited<ReturnType<typeof uploadImageV2>>
>;
export type UploadImageV2MutationBody = BodyType<UploadImageV2Body>;
export type UploadImageV2MutationError = ErrorType<unknown>;

export const useUploadImageV2 = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof uploadImageV2>>,
    TError,
    { data: BodyType<UploadImageV2Body> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof uploadImageV2>>,
  TError,
  { data: BodyType<UploadImageV2Body> },
  TContext
> => {
  const mutationOptions = getUploadImageV2MutationOptions(options);

  return useMutation(mutationOptions);
};
export const getAllQuizzesV2 = (
  searchQuizSetReq: BodyType<SearchQuizSetReq>,
  options?: SecondParameter<typeof httpsInstance>,
) => {
  return httpsInstance<SearchResponseListSearchQuizResV1>(
    {
      url: `/v2/quizzes/search`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: searchQuizSetReq,
    },
    options,
  );
};

export const getGetAllQuizzesV2MutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getAllQuizzesV2>>,
    TError,
    { data: BodyType<SearchQuizSetReq> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof getAllQuizzesV2>>,
  TError,
  { data: BodyType<SearchQuizSetReq> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof getAllQuizzesV2>>,
    { data: BodyType<SearchQuizSetReq> }
  > = props => {
    const { data } = props ?? {};

    return getAllQuizzesV2(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type GetAllQuizzesV2MutationResult = NonNullable<
  Awaited<ReturnType<typeof getAllQuizzesV2>>
>;
export type GetAllQuizzesV2MutationBody = BodyType<SearchQuizSetReq>;
export type GetAllQuizzesV2MutationError = ErrorType<unknown>;

export const useGetAllQuizzesV2 = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getAllQuizzesV2>>,
    TError,
    { data: BodyType<SearchQuizSetReq> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof getAllQuizzesV2>>,
  TError,
  { data: BodyType<SearchQuizSetReq> },
  TContext
> => {
  const mutationOptions = getGetAllQuizzesV2MutationOptions(options);

  return useMutation(mutationOptions);
};
export const updatePlayCountV2 = (
  params: UpdatePlayCountV2Params,
  options?: SecondParameter<typeof httpsInstance>,
) => {
  return httpsInstance<SuccessResponse>(
    { url: `/v2/quizzes/playCount`, method: 'POST', params },
    options,
  );
};

export const getUpdatePlayCountV2MutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updatePlayCountV2>>,
    TError,
    { params: UpdatePlayCountV2Params },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updatePlayCountV2>>,
  TError,
  { params: UpdatePlayCountV2Params },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updatePlayCountV2>>,
    { params: UpdatePlayCountV2Params }
  > = props => {
    const { params } = props ?? {};

    return updatePlayCountV2(params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdatePlayCountV2MutationResult = NonNullable<
  Awaited<ReturnType<typeof updatePlayCountV2>>
>;

export type UpdatePlayCountV2MutationError = ErrorType<unknown>;

export const useUpdatePlayCountV2 = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updatePlayCountV2>>,
    TError,
    { params: UpdatePlayCountV2Params },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updatePlayCountV2>>,
  TError,
  { params: UpdatePlayCountV2Params },
  TContext
> => {
  const mutationOptions = getUpdatePlayCountV2MutationOptions(options);

  return useMutation(mutationOptions);
};
export const getQuizSetByEnterCodeV2 = (
  quizSetEnterReq: BodyType<QuizSetEnterReq>,
  options?: SecondParameter<typeof httpsInstance>,
) => {
  return httpsInstance<BaseResponseQuizDetailsRes>(
    {
      url: `/v2/quizzes/enterCode`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: quizSetEnterReq,
    },
    options,
  );
};

export const getGetQuizSetByEnterCodeV2MutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getQuizSetByEnterCodeV2>>,
    TError,
    { data: BodyType<QuizSetEnterReq> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof getQuizSetByEnterCodeV2>>,
  TError,
  { data: BodyType<QuizSetEnterReq> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof getQuizSetByEnterCodeV2>>,
    { data: BodyType<QuizSetEnterReq> }
  > = props => {
    const { data } = props ?? {};

    return getQuizSetByEnterCodeV2(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type GetQuizSetByEnterCodeV2MutationResult = NonNullable<
  Awaited<ReturnType<typeof getQuizSetByEnterCodeV2>>
>;
export type GetQuizSetByEnterCodeV2MutationBody = BodyType<QuizSetEnterReq>;
export type GetQuizSetByEnterCodeV2MutationError = ErrorType<unknown>;

export const useGetQuizSetByEnterCodeV2 = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getQuizSetByEnterCodeV2>>,
    TError,
    { data: BodyType<QuizSetEnterReq> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof getQuizSetByEnterCodeV2>>,
  TError,
  { data: BodyType<QuizSetEnterReq> },
  TContext
> => {
  const mutationOptions = getGetQuizSetByEnterCodeV2MutationOptions(options);

  return useMutation(mutationOptions);
};
export const uploadImage = (
  uploadImageBody: BodyType<UploadImageBody>,
  options?: SecondParameter<typeof httpsInstance>,
) => {
  return httpsInstance<BaseResponseUploadImageRes>(
    {
      url: `/quiz/upload/image`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: uploadImageBody,
    },
    options,
  );
};

export const getUploadImageMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof uploadImage>>,
    TError,
    { data: BodyType<UploadImageBody> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof uploadImage>>,
  TError,
  { data: BodyType<UploadImageBody> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof uploadImage>>,
    { data: BodyType<UploadImageBody> }
  > = props => {
    const { data } = props ?? {};

    return uploadImage(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UploadImageMutationResult = NonNullable<
  Awaited<ReturnType<typeof uploadImage>>
>;
export type UploadImageMutationBody = BodyType<UploadImageBody>;
export type UploadImageMutationError = ErrorType<unknown>;

export const useUploadImage = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof uploadImage>>,
    TError,
    { data: BodyType<UploadImageBody> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof uploadImage>>,
  TError,
  { data: BodyType<UploadImageBody> },
  TContext
> => {
  const mutationOptions = getUploadImageMutationOptions(options);

  return useMutation(mutationOptions);
};
export const updatePlayCount = (
  params: UpdatePlayCountParams,
  options?: SecondParameter<typeof httpsInstance>,
) => {
  return httpsInstance<SuccessResponse>(
    { url: `/quiz/playCount`, method: 'POST', params },
    options,
  );
};

export const getUpdatePlayCountMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updatePlayCount>>,
    TError,
    { params: UpdatePlayCountParams },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updatePlayCount>>,
  TError,
  { params: UpdatePlayCountParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updatePlayCount>>,
    { params: UpdatePlayCountParams }
  > = props => {
    const { params } = props ?? {};

    return updatePlayCount(params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdatePlayCountMutationResult = NonNullable<
  Awaited<ReturnType<typeof updatePlayCount>>
>;

export type UpdatePlayCountMutationError = ErrorType<unknown>;

export const useUpdatePlayCount = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updatePlayCount>>,
    TError,
    { params: UpdatePlayCountParams },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updatePlayCount>>,
  TError,
  { params: UpdatePlayCountParams },
  TContext
> => {
  const mutationOptions = getUpdatePlayCountMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getQuizSetByEnterCode = (
  quizSetEnterReq: BodyType<QuizSetEnterReq>,
  options?: SecondParameter<typeof httpsInstance>,
) => {
  return httpsInstance<BaseResponseQuizDetailsRes>(
    {
      url: `/quiz/enterCode`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: quizSetEnterReq,
    },
    options,
  );
};

export const getGetQuizSetByEnterCodeMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getQuizSetByEnterCode>>,
    TError,
    { data: BodyType<QuizSetEnterReq> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof getQuizSetByEnterCode>>,
  TError,
  { data: BodyType<QuizSetEnterReq> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof getQuizSetByEnterCode>>,
    { data: BodyType<QuizSetEnterReq> }
  > = props => {
    const { data } = props ?? {};

    return getQuizSetByEnterCode(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type GetQuizSetByEnterCodeMutationResult = NonNullable<
  Awaited<ReturnType<typeof getQuizSetByEnterCode>>
>;
export type GetQuizSetByEnterCodeMutationBody = BodyType<QuizSetEnterReq>;
export type GetQuizSetByEnterCodeMutationError = ErrorType<unknown>;

export const useGetQuizSetByEnterCode = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getQuizSetByEnterCode>>,
    TError,
    { data: BodyType<QuizSetEnterReq> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof getQuizSetByEnterCode>>,
  TError,
  { data: BodyType<QuizSetEnterReq> },
  TContext
> => {
  const mutationOptions = getGetQuizSetByEnterCodeMutationOptions(options);

  return useMutation(mutationOptions);
};
export const createQuizSet = (
  createQuizSetReq: BodyType<CreateQuizSetReq>,
  options?: SecondParameter<typeof httpsInstance>,
) => {
  return httpsInstance<BaseResponseQuizSetDetailsRes>(
    {
      url: `/quiz/create`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: createQuizSetReq,
    },
    options,
  );
};

export const getCreateQuizSetMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createQuizSet>>,
    TError,
    { data: BodyType<CreateQuizSetReq> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createQuizSet>>,
  TError,
  { data: BodyType<CreateQuizSetReq> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createQuizSet>>,
    { data: BodyType<CreateQuizSetReq> }
  > = props => {
    const { data } = props ?? {};

    return createQuizSet(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateQuizSetMutationResult = NonNullable<
  Awaited<ReturnType<typeof createQuizSet>>
>;
export type CreateQuizSetMutationBody = BodyType<CreateQuizSetReq>;
export type CreateQuizSetMutationError = ErrorType<unknown>;

export const useCreateQuizSet = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createQuizSet>>,
    TError,
    { data: BodyType<CreateQuizSetReq> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof createQuizSet>>,
  TError,
  { data: BodyType<CreateQuizSetReq> },
  TContext
> => {
  const mutationOptions = getCreateQuizSetMutationOptions(options);

  return useMutation(mutationOptions);
};
export const generateQuizzesByAI = (
  generateQuizzesByAIReq: BodyType<GenerateQuizzesByAIReq>,
  options?: SecondParameter<typeof httpsInstance>,
) => {
  return httpsInstance<BaseResponseListQuestionDto>(
    {
      url: `/quiz/ai/generate`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: generateQuizzesByAIReq,
    },
    options,
  );
};

export const getGenerateQuizzesByAIMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof generateQuizzesByAI>>,
    TError,
    { data: BodyType<GenerateQuizzesByAIReq> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof generateQuizzesByAI>>,
  TError,
  { data: BodyType<GenerateQuizzesByAIReq> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof generateQuizzesByAI>>,
    { data: BodyType<GenerateQuizzesByAIReq> }
  > = props => {
    const { data } = props ?? {};

    return generateQuizzesByAI(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type GenerateQuizzesByAIMutationResult = NonNullable<
  Awaited<ReturnType<typeof generateQuizzesByAI>>
>;
export type GenerateQuizzesByAIMutationBody = BodyType<GenerateQuizzesByAIReq>;
export type GenerateQuizzesByAIMutationError = ErrorType<unknown>;

export const useGenerateQuizzesByAI = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof generateQuizzesByAI>>,
    TError,
    { data: BodyType<GenerateQuizzesByAIReq> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof generateQuizzesByAI>>,
  TError,
  { data: BodyType<GenerateQuizzesByAIReq> },
  TContext
> => {
  const mutationOptions = getGenerateQuizzesByAIMutationOptions(options);

  return useMutation(mutationOptions);
};
export const deleteQuizSetV2 = (
  deleteQuizSetReq: BodyType<DeleteQuizSetReq>,
  options?: SecondParameter<typeof httpsInstance>,
) => {
  return httpsInstance<SuccessResponse>(
    {
      url: `/v2/quizzes`,
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      data: deleteQuizSetReq,
    },
    options,
  );
};

export const getDeleteQuizSetV2MutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteQuizSetV2>>,
    TError,
    { data: BodyType<DeleteQuizSetReq> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteQuizSetV2>>,
  TError,
  { data: BodyType<DeleteQuizSetReq> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteQuizSetV2>>,
    { data: BodyType<DeleteQuizSetReq> }
  > = props => {
    const { data } = props ?? {};

    return deleteQuizSetV2(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteQuizSetV2MutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteQuizSetV2>>
>;
export type DeleteQuizSetV2MutationBody = BodyType<DeleteQuizSetReq>;
export type DeleteQuizSetV2MutationError = ErrorType<unknown>;

export const useDeleteQuizSetV2 = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteQuizSetV2>>,
    TError,
    { data: BodyType<DeleteQuizSetReq> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteQuizSetV2>>,
  TError,
  { data: BodyType<DeleteQuizSetReq> },
  TContext
> => {
  const mutationOptions = getDeleteQuizSetV2MutationOptions(options);

  return useMutation(mutationOptions);
};
export const editQuizSetV2 = (
  updateQuizSetReq: BodyType<UpdateQuizSetReq>,
  options?: SecondParameter<typeof httpsInstance>,
) => {
  return httpsInstance<BaseResponseQuizSetDetailsRes>(
    {
      url: `/v2/quizzes`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: updateQuizSetReq,
    },
    options,
  );
};

export const getEditQuizSetV2MutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof editQuizSetV2>>,
    TError,
    { data: BodyType<UpdateQuizSetReq> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof editQuizSetV2>>,
  TError,
  { data: BodyType<UpdateQuizSetReq> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof editQuizSetV2>>,
    { data: BodyType<UpdateQuizSetReq> }
  > = props => {
    const { data } = props ?? {};

    return editQuizSetV2(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type EditQuizSetV2MutationResult = NonNullable<
  Awaited<ReturnType<typeof editQuizSetV2>>
>;
export type EditQuizSetV2MutationBody = BodyType<UpdateQuizSetReq>;
export type EditQuizSetV2MutationError = ErrorType<unknown>;

export const useEditQuizSetV2 = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof editQuizSetV2>>,
    TError,
    { data: BodyType<UpdateQuizSetReq> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof editQuizSetV2>>,
  TError,
  { data: BodyType<UpdateQuizSetReq> },
  TContext
> => {
  const mutationOptions = getEditQuizSetV2MutationOptions(options);

  return useMutation(mutationOptions);
};
export const editQuizSet = (
  updateQuizSetReq: BodyType<UpdateQuizSetReq>,
  options?: SecondParameter<typeof httpsInstance>,
) => {
  return httpsInstance<BaseResponseQuizSetDetailsRes>(
    {
      url: `/quiz/edit`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: updateQuizSetReq,
    },
    options,
  );
};

export const getEditQuizSetMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof editQuizSet>>,
    TError,
    { data: BodyType<UpdateQuizSetReq> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof editQuizSet>>,
  TError,
  { data: BodyType<UpdateQuizSetReq> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof editQuizSet>>,
    { data: BodyType<UpdateQuizSetReq> }
  > = props => {
    const { data } = props ?? {};

    return editQuizSet(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type EditQuizSetMutationResult = NonNullable<
  Awaited<ReturnType<typeof editQuizSet>>
>;
export type EditQuizSetMutationBody = BodyType<UpdateQuizSetReq>;
export type EditQuizSetMutationError = ErrorType<unknown>;

export const useEditQuizSet = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof editQuizSet>>,
    TError,
    { data: BodyType<UpdateQuizSetReq> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof editQuizSet>>,
  TError,
  { data: BodyType<UpdateQuizSetReq> },
  TContext
> => {
  const mutationOptions = getEditQuizSetMutationOptions(options);

  return useMutation(mutationOptions);
};
export const deleteQuizSet = (
  deleteQuizSetReq: BodyType<DeleteQuizSetReq>,
  options?: SecondParameter<typeof httpsInstance>,
) => {
  return httpsInstance<SuccessResponse>(
    {
      url: `/quiz/delete`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: deleteQuizSetReq,
    },
    options,
  );
};

export const getDeleteQuizSetMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteQuizSet>>,
    TError,
    { data: BodyType<DeleteQuizSetReq> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteQuizSet>>,
  TError,
  { data: BodyType<DeleteQuizSetReq> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteQuizSet>>,
    { data: BodyType<DeleteQuizSetReq> }
  > = props => {
    const { data } = props ?? {};

    return deleteQuizSet(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteQuizSetMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteQuizSet>>
>;
export type DeleteQuizSetMutationBody = BodyType<DeleteQuizSetReq>;
export type DeleteQuizSetMutationError = ErrorType<unknown>;

export const useDeleteQuizSet = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteQuizSet>>,
    TError,
    { data: BodyType<DeleteQuizSetReq> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteQuizSet>>,
  TError,
  { data: BodyType<DeleteQuizSetReq> },
  TContext
> => {
  const mutationOptions = getDeleteQuizSetMutationOptions(options);

  return useMutation(mutationOptions);
};
export const verifyAuthorV2 = (
  params: VerifyAuthorV2Params,
  options?: SecondParameter<typeof httpsInstance>,
  signal?: AbortSignal,
) => {
  return httpsInstance<BaseResponseQuizAuthorRes>(
    { url: `/v2/quizzes/isAuthor`, method: 'GET', params, signal },
    options,
  );
};

export const getVerifyAuthorV2QueryKey = (params: VerifyAuthorV2Params) => {
  return [`/v2/quizzes/isAuthor`, ...(params ? [params] : [])] as const;
};

export const getVerifyAuthorV2QueryOptions = <
  TData = Awaited<ReturnType<typeof verifyAuthorV2>>,
  TError = ErrorType<unknown>,
>(
  params: VerifyAuthorV2Params,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof verifyAuthorV2>>, TError, TData>
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getVerifyAuthorV2QueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof verifyAuthorV2>>> = ({
    signal,
  }) => verifyAuthorV2(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof verifyAuthorV2>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type VerifyAuthorV2QueryResult = NonNullable<
  Awaited<ReturnType<typeof verifyAuthorV2>>
>;
export type VerifyAuthorV2QueryError = ErrorType<unknown>;

export function useVerifyAuthorV2<
  TData = Awaited<ReturnType<typeof verifyAuthorV2>>,
  TError = ErrorType<unknown>,
>(
  params: VerifyAuthorV2Params,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof verifyAuthorV2>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof verifyAuthorV2>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useVerifyAuthorV2<
  TData = Awaited<ReturnType<typeof verifyAuthorV2>>,
  TError = ErrorType<unknown>,
>(
  params: VerifyAuthorV2Params,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof verifyAuthorV2>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof verifyAuthorV2>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useVerifyAuthorV2<
  TData = Awaited<ReturnType<typeof verifyAuthorV2>>,
  TError = ErrorType<unknown>,
>(
  params: VerifyAuthorV2Params,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof verifyAuthorV2>>, TError, TData>
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useVerifyAuthorV2<
  TData = Awaited<ReturnType<typeof verifyAuthorV2>>,
  TError = ErrorType<unknown>,
>(
  params: VerifyAuthorV2Params,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof verifyAuthorV2>>, TError, TData>
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getVerifyAuthorV2QueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getVerifyAuthorV2SuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof verifyAuthorV2>>,
  TError = ErrorType<unknown>,
>(
  params: VerifyAuthorV2Params,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof verifyAuthorV2>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getVerifyAuthorV2QueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof verifyAuthorV2>>> = ({
    signal,
  }) => verifyAuthorV2(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof verifyAuthorV2>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type VerifyAuthorV2SuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof verifyAuthorV2>>
>;
export type VerifyAuthorV2SuspenseQueryError = ErrorType<unknown>;

export function useVerifyAuthorV2Suspense<
  TData = Awaited<ReturnType<typeof verifyAuthorV2>>,
  TError = ErrorType<unknown>,
>(
  params: VerifyAuthorV2Params,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof verifyAuthorV2>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useVerifyAuthorV2Suspense<
  TData = Awaited<ReturnType<typeof verifyAuthorV2>>,
  TError = ErrorType<unknown>,
>(
  params: VerifyAuthorV2Params,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof verifyAuthorV2>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useVerifyAuthorV2Suspense<
  TData = Awaited<ReturnType<typeof verifyAuthorV2>>,
  TError = ErrorType<unknown>,
>(
  params: VerifyAuthorV2Params,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof verifyAuthorV2>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useVerifyAuthorV2Suspense<
  TData = Awaited<ReturnType<typeof verifyAuthorV2>>,
  TError = ErrorType<unknown>,
>(
  params: VerifyAuthorV2Params,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof verifyAuthorV2>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getVerifyAuthorV2SuspenseQueryOptions(params, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getQuizSetByEntryMapV2 = (
  params: GetQuizSetByEntryMapV2Params,
  options?: SecondParameter<typeof httpsInstance>,
  signal?: AbortSignal,
) => {
  return httpsInstance<BaseResponseQuizEntryMapRes>(
    { url: `/v2/quizzes/entryMap`, method: 'GET', params, signal },
    options,
  );
};

export const getGetQuizSetByEntryMapV2QueryKey = (
  params: GetQuizSetByEntryMapV2Params,
) => {
  return [`/v2/quizzes/entryMap`, ...(params ? [params] : [])] as const;
};

export const getGetQuizSetByEntryMapV2QueryOptions = <
  TData = Awaited<ReturnType<typeof getQuizSetByEntryMapV2>>,
  TError = ErrorType<unknown>,
>(
  params: GetQuizSetByEntryMapV2Params,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getQuizSetByEntryMapV2>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetQuizSetByEntryMapV2QueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getQuizSetByEntryMapV2>>
  > = ({ signal }) => getQuizSetByEntryMapV2(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getQuizSetByEntryMapV2>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetQuizSetByEntryMapV2QueryResult = NonNullable<
  Awaited<ReturnType<typeof getQuizSetByEntryMapV2>>
>;
export type GetQuizSetByEntryMapV2QueryError = ErrorType<unknown>;

export function useGetQuizSetByEntryMapV2<
  TData = Awaited<ReturnType<typeof getQuizSetByEntryMapV2>>,
  TError = ErrorType<unknown>,
>(
  params: GetQuizSetByEntryMapV2Params,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getQuizSetByEntryMapV2>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getQuizSetByEntryMapV2>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetQuizSetByEntryMapV2<
  TData = Awaited<ReturnType<typeof getQuizSetByEntryMapV2>>,
  TError = ErrorType<unknown>,
>(
  params: GetQuizSetByEntryMapV2Params,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getQuizSetByEntryMapV2>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getQuizSetByEntryMapV2>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetQuizSetByEntryMapV2<
  TData = Awaited<ReturnType<typeof getQuizSetByEntryMapV2>>,
  TError = ErrorType<unknown>,
>(
  params: GetQuizSetByEntryMapV2Params,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getQuizSetByEntryMapV2>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetQuizSetByEntryMapV2<
  TData = Awaited<ReturnType<typeof getQuizSetByEntryMapV2>>,
  TError = ErrorType<unknown>,
>(
  params: GetQuizSetByEntryMapV2Params,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getQuizSetByEntryMapV2>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetQuizSetByEntryMapV2QueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getGetQuizSetByEntryMapV2SuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getQuizSetByEntryMapV2>>,
  TError = ErrorType<unknown>,
>(
  params: GetQuizSetByEntryMapV2Params,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getQuizSetByEntryMapV2>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetQuizSetByEntryMapV2QueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getQuizSetByEntryMapV2>>
  > = ({ signal }) => getQuizSetByEntryMapV2(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getQuizSetByEntryMapV2>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetQuizSetByEntryMapV2SuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getQuizSetByEntryMapV2>>
>;
export type GetQuizSetByEntryMapV2SuspenseQueryError = ErrorType<unknown>;

export function useGetQuizSetByEntryMapV2Suspense<
  TData = Awaited<ReturnType<typeof getQuizSetByEntryMapV2>>,
  TError = ErrorType<unknown>,
>(
  params: GetQuizSetByEntryMapV2Params,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getQuizSetByEntryMapV2>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetQuizSetByEntryMapV2Suspense<
  TData = Awaited<ReturnType<typeof getQuizSetByEntryMapV2>>,
  TError = ErrorType<unknown>,
>(
  params: GetQuizSetByEntryMapV2Params,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getQuizSetByEntryMapV2>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetQuizSetByEntryMapV2Suspense<
  TData = Awaited<ReturnType<typeof getQuizSetByEntryMapV2>>,
  TError = ErrorType<unknown>,
>(
  params: GetQuizSetByEntryMapV2Params,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getQuizSetByEntryMapV2>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetQuizSetByEntryMapV2Suspense<
  TData = Awaited<ReturnType<typeof getQuizSetByEntryMapV2>>,
  TError = ErrorType<unknown>,
>(
  params: GetQuizSetByEntryMapV2Params,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getQuizSetByEntryMapV2>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetQuizSetByEntryMapV2SuspenseQueryOptions(
    params,
    options,
  );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getQuizSetDetailsV2 = (
  params: GetQuizSetDetailsV2Params,
  options?: SecondParameter<typeof httpsInstance>,
  signal?: AbortSignal,
) => {
  return httpsInstance<BaseResponseQuizSetDetailsRes>(
    { url: `/v2/quizzes/details`, method: 'GET', params, signal },
    options,
  );
};

export const getGetQuizSetDetailsV2QueryKey = (
  params: GetQuizSetDetailsV2Params,
) => {
  return [`/v2/quizzes/details`, ...(params ? [params] : [])] as const;
};

export const getGetQuizSetDetailsV2QueryOptions = <
  TData = Awaited<ReturnType<typeof getQuizSetDetailsV2>>,
  TError = ErrorType<unknown>,
>(
  params: GetQuizSetDetailsV2Params,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getQuizSetDetailsV2>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetQuizSetDetailsV2QueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getQuizSetDetailsV2>>
  > = ({ signal }) => getQuizSetDetailsV2(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getQuizSetDetailsV2>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetQuizSetDetailsV2QueryResult = NonNullable<
  Awaited<ReturnType<typeof getQuizSetDetailsV2>>
>;
export type GetQuizSetDetailsV2QueryError = ErrorType<unknown>;

export function useGetQuizSetDetailsV2<
  TData = Awaited<ReturnType<typeof getQuizSetDetailsV2>>,
  TError = ErrorType<unknown>,
>(
  params: GetQuizSetDetailsV2Params,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getQuizSetDetailsV2>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getQuizSetDetailsV2>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetQuizSetDetailsV2<
  TData = Awaited<ReturnType<typeof getQuizSetDetailsV2>>,
  TError = ErrorType<unknown>,
>(
  params: GetQuizSetDetailsV2Params,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getQuizSetDetailsV2>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getQuizSetDetailsV2>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetQuizSetDetailsV2<
  TData = Awaited<ReturnType<typeof getQuizSetDetailsV2>>,
  TError = ErrorType<unknown>,
>(
  params: GetQuizSetDetailsV2Params,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getQuizSetDetailsV2>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetQuizSetDetailsV2<
  TData = Awaited<ReturnType<typeof getQuizSetDetailsV2>>,
  TError = ErrorType<unknown>,
>(
  params: GetQuizSetDetailsV2Params,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getQuizSetDetailsV2>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetQuizSetDetailsV2QueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getGetQuizSetDetailsV2SuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getQuizSetDetailsV2>>,
  TError = ErrorType<unknown>,
>(
  params: GetQuizSetDetailsV2Params,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getQuizSetDetailsV2>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetQuizSetDetailsV2QueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getQuizSetDetailsV2>>
  > = ({ signal }) => getQuizSetDetailsV2(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getQuizSetDetailsV2>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetQuizSetDetailsV2SuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getQuizSetDetailsV2>>
>;
export type GetQuizSetDetailsV2SuspenseQueryError = ErrorType<unknown>;

export function useGetQuizSetDetailsV2Suspense<
  TData = Awaited<ReturnType<typeof getQuizSetDetailsV2>>,
  TError = ErrorType<unknown>,
>(
  params: GetQuizSetDetailsV2Params,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getQuizSetDetailsV2>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetQuizSetDetailsV2Suspense<
  TData = Awaited<ReturnType<typeof getQuizSetDetailsV2>>,
  TError = ErrorType<unknown>,
>(
  params: GetQuizSetDetailsV2Params,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getQuizSetDetailsV2>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetQuizSetDetailsV2Suspense<
  TData = Awaited<ReturnType<typeof getQuizSetDetailsV2>>,
  TError = ErrorType<unknown>,
>(
  params: GetQuizSetDetailsV2Params,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getQuizSetDetailsV2>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetQuizSetDetailsV2Suspense<
  TData = Awaited<ReturnType<typeof getQuizSetDetailsV2>>,
  TError = ErrorType<unknown>,
>(
  params: GetQuizSetDetailsV2Params,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getQuizSetDetailsV2>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetQuizSetDetailsV2SuspenseQueryOptions(
    params,
    options,
  );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getAllQuizzes1 = (
  params?: GetAllQuizzes1Params,
  options?: SecondParameter<typeof httpsInstance>,
  signal?: AbortSignal,
) => {
  return httpsInstance<SearchResponseListSearchQuizResV1>(
    { url: `/quiz/search`, method: 'GET', params, signal },
    options,
  );
};

export const getGetAllQuizzes1QueryKey = (params?: GetAllQuizzes1Params) => {
  return [`/quiz/search`, ...(params ? [params] : [])] as const;
};

export const getGetAllQuizzes1QueryOptions = <
  TData = Awaited<ReturnType<typeof getAllQuizzes1>>,
  TError = ErrorType<unknown>,
>(
  params?: GetAllQuizzes1Params,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getAllQuizzes1>>, TError, TData>
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAllQuizzes1QueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllQuizzes1>>> = ({
    signal,
  }) => getAllQuizzes1(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAllQuizzes1>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAllQuizzes1QueryResult = NonNullable<
  Awaited<ReturnType<typeof getAllQuizzes1>>
>;
export type GetAllQuizzes1QueryError = ErrorType<unknown>;

export function useGetAllQuizzes1<
  TData = Awaited<ReturnType<typeof getAllQuizzes1>>,
  TError = ErrorType<unknown>,
>(
  params: undefined | GetAllQuizzes1Params,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getAllQuizzes1>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getAllQuizzes1>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetAllQuizzes1<
  TData = Awaited<ReturnType<typeof getAllQuizzes1>>,
  TError = ErrorType<unknown>,
>(
  params?: GetAllQuizzes1Params,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getAllQuizzes1>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getAllQuizzes1>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetAllQuizzes1<
  TData = Awaited<ReturnType<typeof getAllQuizzes1>>,
  TError = ErrorType<unknown>,
>(
  params?: GetAllQuizzes1Params,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getAllQuizzes1>>, TError, TData>
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetAllQuizzes1<
  TData = Awaited<ReturnType<typeof getAllQuizzes1>>,
  TError = ErrorType<unknown>,
>(
  params?: GetAllQuizzes1Params,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getAllQuizzes1>>, TError, TData>
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetAllQuizzes1QueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getGetAllQuizzes1SuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getAllQuizzes1>>,
  TError = ErrorType<unknown>,
>(
  params?: GetAllQuizzes1Params,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getAllQuizzes1>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAllQuizzes1QueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllQuizzes1>>> = ({
    signal,
  }) => getAllQuizzes1(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getAllQuizzes1>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAllQuizzes1SuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAllQuizzes1>>
>;
export type GetAllQuizzes1SuspenseQueryError = ErrorType<unknown>;

export function useGetAllQuizzes1Suspense<
  TData = Awaited<ReturnType<typeof getAllQuizzes1>>,
  TError = ErrorType<unknown>,
>(
  params: undefined | GetAllQuizzes1Params,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getAllQuizzes1>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetAllQuizzes1Suspense<
  TData = Awaited<ReturnType<typeof getAllQuizzes1>>,
  TError = ErrorType<unknown>,
>(
  params?: GetAllQuizzes1Params,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getAllQuizzes1>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetAllQuizzes1Suspense<
  TData = Awaited<ReturnType<typeof getAllQuizzes1>>,
  TError = ErrorType<unknown>,
>(
  params?: GetAllQuizzes1Params,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getAllQuizzes1>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetAllQuizzes1Suspense<
  TData = Awaited<ReturnType<typeof getAllQuizzes1>>,
  TError = ErrorType<unknown>,
>(
  params?: GetAllQuizzes1Params,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getAllQuizzes1>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetAllQuizzes1SuspenseQueryOptions(params, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const verifyAuthor = (
  params: VerifyAuthorParams,
  options?: SecondParameter<typeof httpsInstance>,
  signal?: AbortSignal,
) => {
  return httpsInstance<BaseResponseQuizAuthorRes>(
    { url: `/quiz/isAuthor`, method: 'GET', params, signal },
    options,
  );
};

export const getVerifyAuthorQueryKey = (params: VerifyAuthorParams) => {
  return [`/quiz/isAuthor`, ...(params ? [params] : [])] as const;
};

export const getVerifyAuthorQueryOptions = <
  TData = Awaited<ReturnType<typeof verifyAuthor>>,
  TError = ErrorType<unknown>,
>(
  params: VerifyAuthorParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof verifyAuthor>>, TError, TData>
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getVerifyAuthorQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof verifyAuthor>>> = ({
    signal,
  }) => verifyAuthor(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof verifyAuthor>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type VerifyAuthorQueryResult = NonNullable<
  Awaited<ReturnType<typeof verifyAuthor>>
>;
export type VerifyAuthorQueryError = ErrorType<unknown>;

export function useVerifyAuthor<
  TData = Awaited<ReturnType<typeof verifyAuthor>>,
  TError = ErrorType<unknown>,
>(
  params: VerifyAuthorParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof verifyAuthor>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof verifyAuthor>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useVerifyAuthor<
  TData = Awaited<ReturnType<typeof verifyAuthor>>,
  TError = ErrorType<unknown>,
>(
  params: VerifyAuthorParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof verifyAuthor>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof verifyAuthor>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useVerifyAuthor<
  TData = Awaited<ReturnType<typeof verifyAuthor>>,
  TError = ErrorType<unknown>,
>(
  params: VerifyAuthorParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof verifyAuthor>>, TError, TData>
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useVerifyAuthor<
  TData = Awaited<ReturnType<typeof verifyAuthor>>,
  TError = ErrorType<unknown>,
>(
  params: VerifyAuthorParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof verifyAuthor>>, TError, TData>
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getVerifyAuthorQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getVerifyAuthorSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof verifyAuthor>>,
  TError = ErrorType<unknown>,
>(
  params: VerifyAuthorParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof verifyAuthor>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getVerifyAuthorQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof verifyAuthor>>> = ({
    signal,
  }) => verifyAuthor(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof verifyAuthor>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type VerifyAuthorSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof verifyAuthor>>
>;
export type VerifyAuthorSuspenseQueryError = ErrorType<unknown>;

export function useVerifyAuthorSuspense<
  TData = Awaited<ReturnType<typeof verifyAuthor>>,
  TError = ErrorType<unknown>,
>(
  params: VerifyAuthorParams,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof verifyAuthor>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useVerifyAuthorSuspense<
  TData = Awaited<ReturnType<typeof verifyAuthor>>,
  TError = ErrorType<unknown>,
>(
  params: VerifyAuthorParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof verifyAuthor>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useVerifyAuthorSuspense<
  TData = Awaited<ReturnType<typeof verifyAuthor>>,
  TError = ErrorType<unknown>,
>(
  params: VerifyAuthorParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof verifyAuthor>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useVerifyAuthorSuspense<
  TData = Awaited<ReturnType<typeof verifyAuthor>>,
  TError = ErrorType<unknown>,
>(
  params: VerifyAuthorParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof verifyAuthor>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getVerifyAuthorSuspenseQueryOptions(params, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getQuizSetByEntryMap = (
  params: GetQuizSetByEntryMapParams,
  options?: SecondParameter<typeof httpsInstance>,
  signal?: AbortSignal,
) => {
  return httpsInstance<BaseResponseQuizEntryMapRes>(
    { url: `/quiz/entryMap`, method: 'GET', params, signal },
    options,
  );
};

export const getGetQuizSetByEntryMapQueryKey = (
  params: GetQuizSetByEntryMapParams,
) => {
  return [`/quiz/entryMap`, ...(params ? [params] : [])] as const;
};

export const getGetQuizSetByEntryMapQueryOptions = <
  TData = Awaited<ReturnType<typeof getQuizSetByEntryMap>>,
  TError = ErrorType<unknown>,
>(
  params: GetQuizSetByEntryMapParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getQuizSetByEntryMap>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetQuizSetByEntryMapQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getQuizSetByEntryMap>>
  > = ({ signal }) => getQuizSetByEntryMap(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getQuizSetByEntryMap>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetQuizSetByEntryMapQueryResult = NonNullable<
  Awaited<ReturnType<typeof getQuizSetByEntryMap>>
>;
export type GetQuizSetByEntryMapQueryError = ErrorType<unknown>;

export function useGetQuizSetByEntryMap<
  TData = Awaited<ReturnType<typeof getQuizSetByEntryMap>>,
  TError = ErrorType<unknown>,
>(
  params: GetQuizSetByEntryMapParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getQuizSetByEntryMap>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getQuizSetByEntryMap>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetQuizSetByEntryMap<
  TData = Awaited<ReturnType<typeof getQuizSetByEntryMap>>,
  TError = ErrorType<unknown>,
>(
  params: GetQuizSetByEntryMapParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getQuizSetByEntryMap>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getQuizSetByEntryMap>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetQuizSetByEntryMap<
  TData = Awaited<ReturnType<typeof getQuizSetByEntryMap>>,
  TError = ErrorType<unknown>,
>(
  params: GetQuizSetByEntryMapParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getQuizSetByEntryMap>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetQuizSetByEntryMap<
  TData = Awaited<ReturnType<typeof getQuizSetByEntryMap>>,
  TError = ErrorType<unknown>,
>(
  params: GetQuizSetByEntryMapParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getQuizSetByEntryMap>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetQuizSetByEntryMapQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getGetQuizSetByEntryMapSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getQuizSetByEntryMap>>,
  TError = ErrorType<unknown>,
>(
  params: GetQuizSetByEntryMapParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getQuizSetByEntryMap>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetQuizSetByEntryMapQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getQuizSetByEntryMap>>
  > = ({ signal }) => getQuizSetByEntryMap(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getQuizSetByEntryMap>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetQuizSetByEntryMapSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getQuizSetByEntryMap>>
>;
export type GetQuizSetByEntryMapSuspenseQueryError = ErrorType<unknown>;

export function useGetQuizSetByEntryMapSuspense<
  TData = Awaited<ReturnType<typeof getQuizSetByEntryMap>>,
  TError = ErrorType<unknown>,
>(
  params: GetQuizSetByEntryMapParams,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getQuizSetByEntryMap>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetQuizSetByEntryMapSuspense<
  TData = Awaited<ReturnType<typeof getQuizSetByEntryMap>>,
  TError = ErrorType<unknown>,
>(
  params: GetQuizSetByEntryMapParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getQuizSetByEntryMap>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetQuizSetByEntryMapSuspense<
  TData = Awaited<ReturnType<typeof getQuizSetByEntryMap>>,
  TError = ErrorType<unknown>,
>(
  params: GetQuizSetByEntryMapParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getQuizSetByEntryMap>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetQuizSetByEntryMapSuspense<
  TData = Awaited<ReturnType<typeof getQuizSetByEntryMap>>,
  TError = ErrorType<unknown>,
>(
  params: GetQuizSetByEntryMapParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getQuizSetByEntryMap>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetQuizSetByEntryMapSuspenseQueryOptions(
    params,
    options,
  );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getQuizSetDetails = (
  params: GetQuizSetDetailsParams,
  options?: SecondParameter<typeof httpsInstance>,
  signal?: AbortSignal,
) => {
  return httpsInstance<BaseResponseQuizSetDetailsRes>(
    { url: `/quiz/details`, method: 'GET', params, signal },
    options,
  );
};

export const getGetQuizSetDetailsQueryKey = (
  params: GetQuizSetDetailsParams,
) => {
  return [`/quiz/details`, ...(params ? [params] : [])] as const;
};

export const getGetQuizSetDetailsQueryOptions = <
  TData = Awaited<ReturnType<typeof getQuizSetDetails>>,
  TError = ErrorType<unknown>,
>(
  params: GetQuizSetDetailsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getQuizSetDetails>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetQuizSetDetailsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getQuizSetDetails>>
  > = ({ signal }) => getQuizSetDetails(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getQuizSetDetails>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetQuizSetDetailsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getQuizSetDetails>>
>;
export type GetQuizSetDetailsQueryError = ErrorType<unknown>;

export function useGetQuizSetDetails<
  TData = Awaited<ReturnType<typeof getQuizSetDetails>>,
  TError = ErrorType<unknown>,
>(
  params: GetQuizSetDetailsParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getQuizSetDetails>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getQuizSetDetails>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetQuizSetDetails<
  TData = Awaited<ReturnType<typeof getQuizSetDetails>>,
  TError = ErrorType<unknown>,
>(
  params: GetQuizSetDetailsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getQuizSetDetails>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getQuizSetDetails>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetQuizSetDetails<
  TData = Awaited<ReturnType<typeof getQuizSetDetails>>,
  TError = ErrorType<unknown>,
>(
  params: GetQuizSetDetailsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getQuizSetDetails>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetQuizSetDetails<
  TData = Awaited<ReturnType<typeof getQuizSetDetails>>,
  TError = ErrorType<unknown>,
>(
  params: GetQuizSetDetailsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getQuizSetDetails>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetQuizSetDetailsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getGetQuizSetDetailsSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getQuizSetDetails>>,
  TError = ErrorType<unknown>,
>(
  params: GetQuizSetDetailsParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getQuizSetDetails>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetQuizSetDetailsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getQuizSetDetails>>
  > = ({ signal }) => getQuizSetDetails(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getQuizSetDetails>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetQuizSetDetailsSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getQuizSetDetails>>
>;
export type GetQuizSetDetailsSuspenseQueryError = ErrorType<unknown>;

export function useGetQuizSetDetailsSuspense<
  TData = Awaited<ReturnType<typeof getQuizSetDetails>>,
  TError = ErrorType<unknown>,
>(
  params: GetQuizSetDetailsParams,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getQuizSetDetails>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetQuizSetDetailsSuspense<
  TData = Awaited<ReturnType<typeof getQuizSetDetails>>,
  TError = ErrorType<unknown>,
>(
  params: GetQuizSetDetailsParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getQuizSetDetails>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetQuizSetDetailsSuspense<
  TData = Awaited<ReturnType<typeof getQuizSetDetails>>,
  TError = ErrorType<unknown>,
>(
  params: GetQuizSetDetailsParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getQuizSetDetails>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetQuizSetDetailsSuspense<
  TData = Awaited<ReturnType<typeof getQuizSetDetails>>,
  TError = ErrorType<unknown>,
>(
  params: GetQuizSetDetailsParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getQuizSetDetails>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetQuizSetDetailsSuspenseQueryOptions(
    params,
    options,
  );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}
