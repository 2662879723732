import { QuizLogo } from '@zep/components';
import { trackGnbAnalytics } from '@zep/layout/Header';
import { useRouter } from 'next/router';
export const HomeLogo = ({
  location,
  isSmall
}: {
  location: 'header' | 'play';
  isSmall?: boolean;
}) => {
  const router = useRouter();
  const goHome = () => {
    trackGnbAnalytics('home', location);
    router.push('/landing');
  };
  if (isSmall) {
    return <img className={'size-[28px] cursor-pointer lg:size-[32px]'} onClick={goHome} src="/assets/logo_small_q.svg" alt="" />;
  }
  return <QuizLogo onClick={goHome} data-sentry-element="QuizLogo" data-sentry-component="HomeLogo" data-sentry-source-file="HomeLogo.tsx" />;
};