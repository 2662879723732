import { v4 as uuidv4 } from 'uuid';

/**
 * 새로고침하기 전까지 유지되는 Application-level Session ID입니다.
 * 새로고침할 때마다 변경됩니다.
 */
function _getApplicationSessionId() {
  let sessionId: string | null = null;

  return function () {
    if (sessionId == null) {
      sessionId = uuidv4();
    }
    return sessionId;
  };
}

export const getApplicationSessionId = _getApplicationSessionId();
