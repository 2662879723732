import { PropsWithChildren } from 'react';
import { THIS_IS_DEFAULT_STATIC_OR_SERVER_SIDE_PROPS } from '@zep/utils';
import { useRouter } from 'next/router';
import { MetaProps } from './Meta';
const WHITE_LIST: RegExp[] = [/^\/admin/];
export const RequireDefaultPageProps = (props: PropsWithChildren<Pick<MetaProps, 'pageProps'>>) => {
  const {
    pathname
  } = useRouter();
  const isProduction = process.env.NODE_ENV === 'production';
  const noDefaultStaticOrServerSideProps = props.pageProps[THIS_IS_DEFAULT_STATIC_OR_SERVER_SIDE_PROPS] !== true;
  const isNotWhiteList = !WHITE_LIST.some(regex => regex.test(pathname));
  if (isProduction) {
    return props.children;
  }
  if (noDefaultStaticOrServerSideProps && isNotWhiteList) {
    throw new Error('페이지 컴포넌트에 GetDefaultStaticProps 또는 GetDefaultServerSideProps를 사용하세요!');
  }
  return props.children;
};