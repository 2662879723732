import { forwardRef } from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cn } from '@zep/utils';
import { cva, VariantProps } from 'class-variance-authority';
const DefaultVariant = cn(['disabled:pointer-events-none disabled:opacity-[.54]', 'rounded-md ring-offset-background focus-visible:ring-ring inline-flex gap-3xs', 'items-center justify-center whitespace-nowrap text-sm font-semibold', 'focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2']);
const buttonVariants = cva(DefaultVariant, {
  variants: {
    intent: {
      gradient: cn(['bg-gradient-to-r from-[#8478FF] to-[#3DA9FF] text-white']),
      strong: cn(['bg-primary text-white hover:bg-[#5246cc]']),
      alertNative: cn(['bg-[#F3F2FF] text-[#5748F2]']),
      neutral: cn(['border border-[rgba(28,27,31,0.04)] bg-[#F8F9FC] text-[#5D5E69] hover:bg-[#3E3E47]/[.04]']),
      destructive: 'hover:bg-destructive/90 bg-[#FFF2F7] text-[#FF4848]'
    },
    size: {
      '3xs': 'h-[18px] min-w-[18px] rounded-[4px] px-3xs text-caption-md font-medium',
      '2xs': 'h-[26px] min-w-[26px] rounded-2xs px-2xs text-caption-md font-semibold',
      xs: 'h-[30px] min-w-[30ppx] rounded-xs px-xs text-caption-lg font-semibold',
      sm: 'h-[36px] min-w-[36px] rounded-[8px] px-sm text-body-sm font-semibold',
      md: 'h-[40px] min-w-[40px] rounded-[8px] px-md text-body-md font-semibold',
      lg: 'h-[48px] min-w-[48px] rounded-[8px] px-lg text-body-lg font-semibold'
    }
  },
  defaultVariants: {
    intent: 'strong',
    size: 'lg'
  }
});
export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  rounded?: boolean;
  transParented?: boolean;
}
const Button = forwardRef<HTMLButtonElement, ButtonProps>(({
  className,
  size,
  asChild = false,
  rounded,
  intent,
  transParented,
  ...props
}, ref) => {
  const Comp = asChild ? Slot : 'button';
  return <Comp className={cn(buttonVariants({
    size,
    className,
    intent
  }), rounded && 'rounded-[80px]', transParented && 'bg-transparent  border-transparent', className)} ref={ref} {...props} />;
});
Button.displayName = 'Button';
export { Button, buttonVariants };