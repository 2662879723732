import type { SVGProps } from 'react';
export const Authority3Color = (props: SVGProps<SVGSVGElement>) => <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props} data-sentry-element="svg" data-sentry-component="Authority3Color" data-sentry-source-file="Authority3Color.tsx">
    <path fill="#E28724" d="M24 12c0 6.627-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0s12 5.373 12 12" data-sentry-element="path" data-sentry-source-file="Authority3Color.tsx" />
    <path fill="url(#Authority-3-color_svg__a)" d="M21.913 11.999c0 5.475-4.438 9.913-9.913 9.913s-9.913-4.438-9.913-9.913S6.525 2.086 12 2.086s9.913 4.438 9.913 9.913" data-sentry-element="path" data-sentry-source-file="Authority3Color.tsx" />
    <path fill="url(#Authority-3-color_svg__b)" d="M20.348 12a8.348 8.348 0 1 1-16.696 0 8.348 8.348 0 0 1 16.696 0" data-sentry-element="path" data-sentry-source-file="Authority3Color.tsx" />
    <path fill="#1C1B1F" fillOpacity={0.54} d="M10.786 12.73h1.135q.763 0 1.24.364.48.357.479.935 0 .492-.243.87-.242.37-.678.578-.427.207-1.012.207-.564 0-.992-.207a1.55 1.55 0 0 1-.649-.57 1.3 1.3 0 0 1-.178-.814v-.021H7.862l-.029.235a2.584 2.584 0 0 0 .492 1.74q.493.671 1.356 1.056.87.378 1.997.378 1.198 0 2.119-.42.927-.421 1.455-1.156t.528-1.684q0-.834-.464-1.383-.464-.556-1.327-.75l.022-.128q.7-.093 1.24-.456a2.66 2.66 0 0 0 .864-.92 2.5 2.5 0 0 0 .314-1.227q0-.786-.45-1.391-.45-.614-1.24-.956-.785-.35-1.806-.35-1.012 0-1.84.364a3.6 3.6 0 0 0-1.37 1.02 3.57 3.57 0 0 0-.727 1.562h1.99q.171-.556.656-.891a1.9 1.9 0 0 1 1.106-.336q.478 0 .835.157.363.158.563.435.2.278.2.65 0 .442-.236.784-.228.336-.649.528-.42.185-.977.185h-1.37z" data-sentry-element="path" data-sentry-source-file="Authority3Color.tsx" />
    <defs data-sentry-element="defs" data-sentry-source-file="Authority3Color.tsx">
      <linearGradient id="Authority-3-color_svg__a" x1={6.632} x2={15.79} y1={0} y2={24} gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="Authority3Color.tsx">
        <stop stopColor="#BF5A11" data-sentry-element="stop" data-sentry-source-file="Authority3Color.tsx" />
        <stop offset={1} stopColor="#FFAE34" data-sentry-element="stop" data-sentry-source-file="Authority3Color.tsx" />
      </linearGradient>
      <linearGradient id="Authority-3-color_svg__b" x1={6.632} x2={15.79} y1={0} y2={24} gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="Authority3Color.tsx">
        <stop stopColor="#FBA832" data-sentry-element="stop" data-sentry-source-file="Authority3Color.tsx" />
        <stop offset={1} stopColor="#E88E08" data-sentry-element="stop" data-sentry-source-file="Authority3Color.tsx" />
      </linearGradient>
    </defs>
  </svg>;