import { PropsWithChildren, ReactElement } from 'react';
import { Conditional } from '@zep/shared/googleClassroom';
import { StickyBanner } from '../components/StickyBanner/';
import { GnbHeader } from './Header/GnbHeader';
import { GnbHeaderForGoogleClassroom } from './Header/GnbHeaderForGoogleClassroom';
const RootLayout = (props: PropsWithChildren) => {
  return <>
      <Conditional inGoogleClassroom={null} data-sentry-element="Conditional" data-sentry-source-file="getRootLayout.tsx">
        <StickyBanner data-sentry-element="StickyBanner" data-sentry-source-file="getRootLayout.tsx" />
      </Conditional>
      <Conditional inGoogleClassroom={<GnbHeaderForGoogleClassroom />} data-sentry-element="Conditional" data-sentry-source-file="getRootLayout.tsx">
        <GnbHeader data-sentry-element="GnbHeader" data-sentry-source-file="getRootLayout.tsx" />
      </Conditional>
      {props.children}
    </>;
};
export function getRootLayout(page: ReactElement) {
  return <RootLayout data-sentry-element="RootLayout" data-sentry-component="getRootLayout" data-sentry-source-file="getRootLayout.tsx">{page}</RootLayout>;
}
export function getLightLayout(page: ReactElement) {
  return <>
      <Conditional inGoogleClassroom={<GnbHeaderForGoogleClassroom />} data-sentry-element="Conditional" data-sentry-source-file="getRootLayout.tsx">
        <GnbHeader data-sentry-element="GnbHeader" data-sentry-source-file="getRootLayout.tsx" />
      </Conditional>
      {page}
    </>;
}