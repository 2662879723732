import { useTranslation } from 'next-i18next';
import { Value1Icon, Value2Icon, Value3Icon } from '../icons';
export const Achievement = () => {
  const {
    t
  } = useTranslation(['landing', 'common']);
  const ACHIEVEMENTS = [{
    title: t('landing.amount.user'),
    amount: t('landingV2.number.user'),
    unit: t('landingV2.unit.user'),
    icon: <Value1Icon className="size-[50px] min-[751px]:size-[60px]" />
  }, {
    title: t('landing.amount.quiz'),
    amount: t('landingV2.number.quiz'),
    unit: t('landing.unit.quiz'),
    icon: <Value2Icon className="size-[50px] min-[751px]:size-[60px]" />
  }, {
    title: t('landing.amount.play'),
    amount: t('landingV2.number.play'),
    unit: t('landing.unit.play'),
    icon: <Value3Icon className="size-[50px] min-[751px]:size-[60px]" />
  }];
  return <section className="flex flex-col items-center justify-center gap-xl px-[16px] py-2xl min-[751px]:flex-row min-[751px]:px-[24px] min-[1025px]:gap-3xl min-[1025px]:px-[40px] min-[1025px]:py-3xl" data-sentry-component="Achievement" data-sentry-source-file="Achievement.tsx">
      {ACHIEVEMENTS.map(achievement => <div key={achievement.title} className="flex items-center gap-md">
          {achievement.icon}

          <div className="flex flex-col">
            <span className="text-body-lg-bold text-neutral opacity-60 min-[1025px]:text-heading-sm-bold">
              {achievement.title}
            </span>
            <div className="flex items-end gap-[7px]">
              <strong className="text-[40px] font-extrabold leading-[1] text-default min-[1025px]:text-[62px]">
                {achievement.amount}
              </strong>
              <span className="text-heading-md-bold text-neutral min-[1025px]:text-heading-lg-extrabold">
                {achievement.unit}
              </span>
            </div>
          </div>
        </div>)}
    </section>;
};