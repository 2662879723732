import { HTMLProps, PropsWithChildren } from 'react';
import { cn } from '../../../utils';
type OptionalClassName = Partial<Pick<HTMLDivElement, 'className'>>;
export const Dialog = ({
  className,
  ...props
}: HTMLProps<HTMLDivElement>) => {
  return <div className={cn('z-[300] pointer-events-auto fixed z-modal flex flex-col items-center justify-center p-[10px] md:p-[40px] inset-0 backdrop-brightness-75', className)} {...props} data-sentry-component="Dialog" data-sentry-source-file="Dialog.tsx"></div>;
};
export const DialogPanel = ({
  className,
  children
}: PropsWithChildren<OptionalClassName>) => <div className={cn('relative z-[80] flex flex-col w-full max-w-[400px] rounded  max-h-full overflow-hidden bg-white outline-none', className)} onClick={e => {
  e.stopPropagation();
}} data-sentry-component="DialogPanel" data-sentry-source-file="Dialog.tsx">
    {children}
  </div>;
export const DialogTitle = ({
  className,
  children
}: PropsWithChildren<OptionalClassName>) => {
  return <div className={cn('whitespace-pre-line flex-1 font-semibold text-[18px] text-gray-700 leading-normal', className)} data-sentry-component="DialogTitle" data-sentry-source-file="Dialog.tsx">
      {children}
    </div>;
};
export const DialogContent = ({
  className,
  children
}: PropsWithChildren<OptionalClassName>) => <div className={cn('flex-1 p-[24px] overflow-x-hidden overflow-y-auto', className)} data-sentry-component="DialogContent" data-sentry-source-file="Dialog.tsx">
    {children}
  </div>;
export const DialogFooter = ({
  className,
  children
}: PropsWithChildren<OptionalClassName>) => <div className={cn('relative flex flex-wrap gap-xs p-[24px]', className)} data-sentry-component="DialogFooter" data-sentry-source-file="Dialog.tsx">
    {children}
  </div>;