import type { SVGProps } from 'react';
import * as React from 'react';
export const Value1 = (props: SVGProps<SVGSVGElement>) => <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 60 60" {...props} data-sentry-element="svg" data-sentry-component="Value1" data-sentry-source-file="Value1.tsx">
    <rect width={60} height={60} fill="#3CD84C" rx={30} data-sentry-element="rect" data-sentry-source-file="Value1.tsx" />
    <g clipPath="url(#value1_svg__a)" data-sentry-element="g" data-sentry-source-file="Value1.tsx">
      <path fill="#fff" d="M28.04 42.578s-2.15 0-2.15-2.148 2.15-8.594 10.743-8.594 10.742 6.445 10.742 8.594-2.148 2.148-2.148 2.148zm8.593-12.89a6.445 6.445 0 1 0 0-12.891 6.445 6.445 0 0 0 0 12.89m-12.427 12.89a4.8 4.8 0 0 1-.464-2.148c0-2.911 1.461-5.908 4.16-7.992a13.5 13.5 0 0 0-4.16-.602C15.148 31.836 13 38.28 13 40.43s2.148 2.148 2.148 2.148zm-1.538-12.89a5.371 5.371 0 1 0 0-10.742 5.371 5.371 0 0 0 0 10.741" data-sentry-element="path" data-sentry-source-file="Value1.tsx" />
    </g>
    <defs data-sentry-element="defs" data-sentry-source-file="Value1.tsx">
      <clipPath id="value1_svg__a" data-sentry-element="clipPath" data-sentry-source-file="Value1.tsx">
        <path fill="#fff" d="M13 12.5h34.375v34.375H13z" data-sentry-element="path" data-sentry-source-file="Value1.tsx" />
      </clipPath>
    </defs>
  </svg>;