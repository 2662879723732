import { useEffect } from 'react';
import { sendPageView } from '@zep/utils/analytics';
import { useRouter } from 'next/router';
export const useRouteChangeTracker = () => {
  const router = useRouter();
  useEffect(() => {
    const handleRouteChange = (url: string) => {
      sendPageView(url);
    };
    router.events.on('routeChangeStart', handleRouteChange);
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router]);
};