import ReactGA from 'react-ga4';

export * from './types';
export * from './getDeviceInformation';
export * from './utmHelper';

export const sendPageView = (page: string) => {
  ReactGA.set({ page });
  ReactGA.send('pageview');
};

export * from './zepAnalytics';
export * from './zettaClient';
