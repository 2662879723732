/** 언어가 추가되는 변수입니다.
 * ./localize-js.js 에도 언어를 추가해야 합니다.
 * 아래 슬랙을 참고해 필요한 다른 작업들도 병행해 주세요.
 * https://zep-us.slack.com/archives/C07374E939S/p1734357871197999?thread_ts=1733914597.693229&cid=C07374E939S
 */
export const SUPPORTED_LOCALES_OBJ = {
  ko: 'ko',
  en: 'en',
  ja: 'ja',
  id: 'id',
  th: 'th',
  fil: 'fil',
  vi: 'vi',
  ms: 'ms',
  uz: 'uz',
  fr: 'fr',
  km: 'km',
  ru: 'ru',
  'zh-CN': 'zh-CN',
  'zh-TW': 'zh-TW',
} as const;

export const LOCALE_MAP: Record<string, SupportedLocale> = {
  'zh-Hans': 'zh-CN',
  'zh-Hant': 'zh-TW',
};

export const SUPPORTED_LOCALES_ARR = Object.values(SUPPORTED_LOCALES_OBJ);

export type SupportedLocalesObject = typeof SUPPORTED_LOCALES_OBJ;
export type SupportedLocalesArray = typeof SUPPORTED_LOCALES_ARR;

export type SupportedLocale = SupportedLocalesArray[number];

/**
 * @example
 * SupportedLocale = 'ko' | 'en' | 'ja' | 'id' 일 때,
 * SupportedLocaleMap<'ko' | 'en' | 'ja', string>는 다음과 같습니다:
 * {
 *   ko: string;
 *   en: string;
 *   ja: string;
 *   id?: string; // 'id'는 선택적입니다.
 * }
 */
export type SupportedLocaleMap<
  T extends SupportedLocale = SupportedLocale,
  U = string,
> = Record<T, U> & Partial<Record<Exclude<SupportedLocale, T>, U>>;

export const SUPPORTED_COUNTRY_CODES_ARR = ['KR', 'US', 'JP'] as const;
export type SupportedCountryCode = (typeof SUPPORTED_COUNTRY_CODES_ARR)[number];
export function isSupportedCountryCode(
  code: string,
): code is SupportedCountryCode {
  return SUPPORTED_COUNTRY_CODES_ARR.includes(code as any);
}
