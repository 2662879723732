import { ComponentPropsWithoutRef, ReactNode } from 'react';
import { SectionItem } from '@zep/module/payment/Pricing/SectionItem';
import { cn } from '@zep/utils';
type MinReactNodes = [React.ReactNode, React.ReactNode, React.ReactNode, ...React.ReactNode[]];
export type PricingCardContents = ComponentPropsWithoutRef<'div'> & {
  contents: MinReactNodes;
  bgImage?: ReactNode;
};
export type PricingCardProps = ComponentPropsWithoutRef<'div'> & {
  top: PricingCardContents;
  bottom: PricingCardContents;
  ActionButton: ReactNode;
};
export const PricingCard = (props: PricingCardProps) => {
  const {
    top,
    bottom,
    ActionButton,
    ...rest
  } = props;
  return <SectionItem style={{
    boxShadow: '0 4px 8px 4px rgba(28, 27, 31, 0.04)',
    ...rest.style
  }} {...rest} className={cn(['rounded-[24px] bg-white', 'min-[976px]:w-[400px] min-w-[340px]', props.className])} data-sentry-element="SectionItem" data-sentry-component="PricingCard" data-sentry-source-file="PricingCard.tsx">
      <PricingCardTop {...top} data-sentry-element="PricingCardTop" data-sentry-source-file="PricingCard.tsx" />
      <PricingCardBottom {...bottom} data-sentry-element="PricingCardBottom" data-sentry-source-file="PricingCard.tsx" />
      <div className={'flex w-full justify-center p-xl pt-xs'}>
        {ActionButton}
      </div>
    </SectionItem>;
};
const PricingCardTop = (props: PricingCardContents) => {
  const {
    contents,
    bgImage,
    ...restProps
  } = props;
  return <div {...restProps} className={cn('flex flex-col relative gap-md pt-xl pb-lg px-xl rounded-t-[24px]', 'min-h-[200px]', props.className)} data-sentry-component="PricingCardTop" data-sentry-source-file="PricingCard.tsx">
      {bgImage}
      <div className={'flex flex-col gap-3xs'}>
        <div className={'text-heading-lg font-extrabold'}>{contents[0]}</div>
        <div className={'text-body-lg font-medium text-neutral'}>
          {contents[1]}
        </div>
      </div>
      <div>
        <div className={'text-heading-lg font-extrabold text-gray-800'}>
          {contents[2]}
        </div>
      </div>
    </div>;
};
const PricingCardBottom = (props: PricingCardContents) => {
  const {
    contents,
    ...restProps
  } = props;
  return <div className={'min-h-[146px] px-xl py-lg'} data-sentry-component="PricingCardBottom" data-sentry-source-file="PricingCard.tsx">
      <div {...restProps} className={cn('flex flex-col gap-[16px]', props.className)}>
        {/* title : 무료로 제공하는 모든 기능  */}
        <h1 className={'text-body-md font-extrabold'}>{contents[0]}</h1>
        {/* */}
        <div className={'flex flex-col gap-sm'}>
          {contents.slice(1, contents.length).map((desc, index) => <div key={index} className={'text-body-sm font-medium'}>
              {desc}
            </div>)}
        </div>
      </div>
    </div>;
};