import type { SVGProps } from 'react';
export const CoinColorV2 = (props: SVGProps<SVGSVGElement>) => <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props} data-sentry-element="svg" data-sentry-component="CoinColorV2" data-sentry-source-file="CoinColorV2.tsx">
    <path fill="url(#Coin-color-V2_svg__a)" d="M12.855 2.002h-1.709v19.636h1.708c4.5 0 8.146-4.395 8.146-9.818s-3.647-9.818-8.145-9.818" data-sentry-element="path" data-sentry-source-file="CoinColorV2.tsx" />
    <path fill="#D5922E" d="M11.147 21.103v.532h1.707c1.913 0 3.668-.795 5.058-2.123l-6.765.075z" style={{
    mixBlendMode: 'multiply'
  }} data-sentry-element="path" data-sentry-source-file="CoinColorV2.tsx" />
    <path fill="url(#Coin-color-V2_svg__b)" d="M11.146 21.636c4.499 0 8.146-4.396 8.146-9.818S15.645 2 11.146 2 3 6.396 3 11.818s3.647 9.818 8.146 9.818" data-sentry-element="path" data-sentry-source-file="CoinColorV2.tsx" />
    <path fill="url(#Coin-color-V2_svg__c)" d="M11.144 21.316c4.352 0 7.879-4.252 7.879-9.496s-3.527-9.496-7.879-9.496-7.878 4.252-7.878 9.496 3.527 9.496 7.878 9.496" data-sentry-element="path" data-sentry-source-file="CoinColorV2.tsx" />
    <path fill="#FFFBE5" d="M11.328 18.839c3.095 0 5.604-3.024 5.604-6.755S14.423 5.33 11.328 5.33c-3.094 0-5.603 3.024-5.603 6.754s2.508 6.755 5.603 6.755" data-sentry-element="path" data-sentry-source-file="CoinColorV2.tsx" />
    <path fill="url(#Coin-color-V2_svg__d)" d="M11.145 18.573c3.095 0 5.604-3.024 5.604-6.754s-2.51-6.755-5.604-6.755S5.54 8.088 5.54 11.82s2.509 6.754 5.604 6.754" data-sentry-element="path" data-sentry-source-file="CoinColorV2.tsx" />
    <path fill="#F1B10B" d="M5.916 12.39c0-3.73 2.509-6.754 5.604-6.754 1.674 0 3.175.885 4.202 2.286-1.013-1.73-2.686-2.858-4.577-2.858-3.093 0-5.604 3.024-5.604 6.754 0 1.715.53 3.276 1.402 4.467a7.7 7.7 0 0 1-1.027-3.895" data-sentry-element="path" data-sentry-source-file="CoinColorV2.tsx" />
    <mask id="Coin-color-V2_svg__e" width={17} height={20} x={3} y={2} maskUnits="userSpaceOnUse" style={{
    maskType: 'alpha'
  }} data-sentry-element="mask" data-sentry-source-file="CoinColorV2.tsx">
      <path fill="#FED425" d="M11.144 21.316c4.352 0 7.879-4.252 7.879-9.496s-3.527-9.496-7.879-9.496-7.878 4.252-7.878 9.496 3.527 9.496 7.878 9.496" data-sentry-element="path" data-sentry-source-file="CoinColorV2.tsx" />
    </mask>
    <g mask="url(#Coin-color-V2_svg__e)" data-sentry-element="g" data-sentry-source-file="CoinColorV2.tsx">
      <path fill="#FFE350" fillRule="evenodd" d="M7.911 17.44A25.4 25.4 0 0 1 4.8 19.28v4.86h16.29V5.24h-4.32c-.14.733-.441 1.71-.937 2.827.69 1.122 1.098 2.512 1.098 4.017 0 3.73-2.509 6.755-5.603 6.755-1.286 0-2.47-.522-3.416-1.4" clipRule="evenodd" style={{
      mixBlendMode: 'soft-light'
    }} data-sentry-element="path" data-sentry-source-file="CoinColorV2.tsx" />
    </g>
    <path fill="#fff" d="M11.146 2.36c4.449 0 8.062 4.299 8.142 9.638 0-.06.004-.12.004-.18C19.292 6.396 15.645 2 11.146 2S3 6.396 3 11.818q-.001.09.004.18c.08-5.34 3.693-9.639 8.142-9.639" style={{
    mixBlendMode: 'soft-light'
  }} data-sentry-element="path" data-sentry-source-file="CoinColorV2.tsx" />
    <defs data-sentry-element="defs" data-sentry-source-file="CoinColorV2.tsx">
      <linearGradient id="Coin-color-V2_svg__a" x1={16.073} x2={16.073} y1={2.002} y2={21.638} gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="CoinColorV2.tsx">
        <stop offset={0.11} stopColor="#F09C1E" data-sentry-element="stop" data-sentry-source-file="CoinColorV2.tsx" />
        <stop offset={0.572} stopColor="#B55200" data-sentry-element="stop" data-sentry-source-file="CoinColorV2.tsx" />
      </linearGradient>
      <linearGradient id="Coin-color-V2_svg__b" x1={11.146} x2={11.146} y1={2} y2={21.636} gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="CoinColorV2.tsx">
        <stop stopColor="#FEE475" data-sentry-element="stop" data-sentry-source-file="CoinColorV2.tsx" />
        <stop offset={1} stopColor="#FFD834" data-sentry-element="stop" data-sentry-source-file="CoinColorV2.tsx" />
      </linearGradient>
      <linearGradient id="Coin-color-V2_svg__c" x1={11.144} x2={11.144} y1={2.324} y2={21.316} gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="CoinColorV2.tsx">
        <stop stopColor="#FFE91F" data-sentry-element="stop" data-sentry-source-file="CoinColorV2.tsx" />
        <stop offset={1} stopColor="#FFCE00" data-sentry-element="stop" data-sentry-source-file="CoinColorV2.tsx" />
      </linearGradient>
      <linearGradient id="Coin-color-V2_svg__d" x1={11.145} x2={11.145} y1={5.064} y2={18.573} gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="CoinColorV2.tsx">
        <stop stopColor="#FECA12" data-sentry-element="stop" data-sentry-source-file="CoinColorV2.tsx" />
        <stop offset={1} stopColor="#FEB724" data-sentry-element="stop" data-sentry-source-file="CoinColorV2.tsx" />
      </linearGradient>
    </defs>
  </svg>;