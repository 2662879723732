import posthog from 'posthog-js';

import { EventProperties } from './types';

interface ExternalEventTrackingClient {
  identify: (userId: string, eventProperties?: EventProperties) => void;
  resetIdentity: () => void;
  setUserProperties: (eventProperties: EventProperties) => void;
  track: (eventName: string, eventProperties?: EventProperties) => void;
}

export class ZettaClient implements ExternalEventTrackingClient {
  constructor() {
    posthog.init(process.env.NEXT_PUBLIC_POSTHOG_API_KEY ?? '', {
      api_host: process.env.NEXT_PUBLIC_POSTHOG_API_HOST ?? '',
      capture_pageview: false,
      capture_pageleave: false,
      autocapture: false,
      disable_session_recording: true,
      debug: false,
    });
  }

  public identify(userId: string, eventProperties?: EventProperties) {
    posthog.identify(userId, eventProperties);
  }

  public resetIdentity() {
    posthog.reset();
  }

  public setUserProperties(eventProperties: EventProperties) {
    posthog.setPersonProperties(eventProperties);
  }

  public track(eventName: string, eventProperties?: EventProperties) {
    posthog.capture(eventName, eventProperties);
  }
}

export const zettaClient = new ZettaClient();
