import { ComponentPropsWithoutRef, PropsWithChildren, ReactNode } from 'react';
import { BoltFillIcon, MapIcon, SparklingFillIcon } from '@zep/icons';
import { useUser } from '@zep/lib/auth';
import { PricingSection, usePlanInquiryLink } from '@zep/module/payment';
import { Button } from '@zep/ui';
import { cn, combinePathAndQuery } from '@zep/utils';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { PricingCard, PricingCardContents } from './PricingCard';
import S from './PricingSection1.module.scss';
export const PricingSection1 = () => {
  const {
    t
  } = useTranslation();
  const locales = {
    title: t('home.pricing.allUseZepQuiz', 'ZEP 퀴즈의 모든 기능을 이용해 보세요')
  };
  return <PricingSection style={{
    background: 'linear-gradient(180deg, #FFF 0%, #F0F2F6 40%, #F0F2F6 60%, #FFF 100%)'
  }} className={cn(['flex flex-col gap-3xl', 'min-[561px]:px-xl min-[561px]:py-2xl', 'max-[560px]:px-md max-[560px]:py-xl'])} data-sentry-element="PricingSection" data-sentry-component="PricingSection1" data-sentry-source-file="PricingSection1.tsx">
      <div className={'flex flex-col items-center gap-2xl min-[1025px]:gap-3xl'}>
        <Title data-sentry-element="Title" data-sentry-source-file="PricingSection1.tsx">{locales.title}</Title>
        <Row data-sentry-element="Row" data-sentry-source-file="PricingSection1.tsx">
          <FreePricingCard data-sentry-element="FreePricingCard" data-sentry-source-file="PricingSection1.tsx" />
          <PaidPricingCard data-sentry-element="PaidPricingCard" data-sentry-source-file="PricingSection1.tsx" />
        </Row>
      </div>
    </PricingSection>;
};
const Row = (props: PropsWithChildren) => {
  return <div className={cn(cn('flex gap-lg flex-col', S.card_container))} data-sentry-component="Row" data-sentry-source-file="PricingSection1.tsx">
      {props.children}
    </div>;
};
const Title = (props: PropsWithChildren) => {
  return <div className={cn('flex items-center gap-[6px]', 'flex-col text-heading-lg', 'min-[751px]:flex-row min-[751px]:text-heading-xl')} data-sentry-component="Title" data-sentry-source-file="PricingSection1.tsx">
      <BoltFillIcon size="xl" fill={'#5D5E69'} data-sentry-element="BoltFillIcon" data-sentry-source-file="PricingSection1.tsx" />
      <span className={'text-center font-extrabold text-gray-700'}>
        {props.children}
      </span>
    </div>;
};
const CardBottomContentItem = (props: ComponentPropsWithoutRef<'div'> & {
  icon: ReactNode;
}) => {
  const {
    icon,
    ...rest
  } = props;
  return <div className={'flex items-center gap-[6px] text-body-lg font-medium text-neutral'} {...rest} data-sentry-component="CardBottomContentItem" data-sentry-source-file="PricingSection1.tsx">
      {icon}
      <div className={'flex items-center gap-xs'}>{props.children}</div>
    </div>;
};
export const FreePricingCard = () => {
  const {
    t
  } = useTranslation();
  const {
    isAuthenticated
  } = useUser();
  const router = useRouter();
  const handleFreePage = () => {
    router.push(isAuthenticated ? '/build' : combinePathAndQuery('/login', router.query));
  };
  const top: PricingCardContents = {
    style: {
      background: 'linear-gradient(180deg, #F8F9FC 0%, #F0F2F6 100%)'
    },
    contents: [<h1 key={'1'} className={'uppercase text-neutral'}>
        FREE PLAN
      </h1>, t('home.pricing.limitedUseFunction', '제한된 기능을 사용할 수 있는 체험판 요금제'), t('home.pricing.free', '무료'), '']
  };
  const bottom: PricingCardContents = {
    contents: [t('home.pricing.freeAllFunction', '무료로 제공하는 모든 기능'), <CardBottomContentItem key={2} icon={<MapIcon width={20} height={20} fill={'#9E9FAF'} />}>
        {t('home.pricing.defaultMapEnabled', '기본 제공 맵 사용 가능')}
      </CardBottomContentItem>, <CardBottomContentItem key={1} icon={<SparklingFillIcon width={20} height={20} fill={'#9E9FAF'} />}>
        {t('home.pricing.disabledAIMake', 'AI 문제 생성 불가')}
      </CardBottomContentItem>]
  };
  return <PricingCard className={cn(['border border-gray-200 bg-white'])} ActionButton={<Button size={'lg'} intent={'neutral'} onClick={handleFreePage} className={'w-full bg-[#F8F9FC] text-body-lg font-semibold text-neutral'}>
          {t('home.pricing.startFree', '무료로 시작하기')}
        </Button>} top={top} bottom={bottom} data-sentry-element="PricingCard" data-sentry-component="FreePricingCard" data-sentry-source-file="PricingSection1.tsx" />;
};
export const PaidPricingCard = () => {
  const {
    t
  } = useTranslation();
  const goLink = usePlanInquiryLink();
  const top: PricingCardContents = {
    style: {
      backgroundImage: 'linear-gradient(99deg, #A49BFF 0%, #18D0B4 100%)'
    },
    //  border-2px 로 인해 2px 로 줄입니다
    className: 'text-white rounded-t-[22px]',
    bgImage: <img className={'absolute bottom-0 right-0 max-h-[113px]'} alt={'bg'} src={'/assets/pricing/pricing_card_bg.png'} />,
    contents: [<h1 key={'1'} className={'uppercase'}>
        PRO PLAN
      </h1>, <span key={'top-2'} className={'text-white'}>
        {t('home.pricing.min3GroupPlan', '3명부터 이용 가능한 단체 요금제')}
      </span>, <span className={'text-white'} key={'dd'}>
        {t('home.pricing.IntroduceInquiry', '도입 문의')}
      </span>, '']
  };
  const bottom: PricingCardContents = {
    contents: [<span className={'text-strong'} key={'20'}>
        {t('home.pricing.quizProAllFunction', '퀴즈 프로 요금제의 모든 기능')}
      </span>, <CardBottomContentItem key={'21'} icon={<MapIcon width={20} height={20} fill={'#9E9FAF'} />}>
        {t('home.pricing.allUsedMap', '모든 맵 사용 가능')}
      </CardBottomContentItem>, <CardBottomContentItem key={'22'} icon={<SparklingFillIcon width={20} height={20} fill={'#B8B9C8'} />}>
        {t('home.pricing.infinityMakeAiQuiz', '무제한 AI 문제 만들기')}
      </CardBottomContentItem>]
  };
  return <PricingCard style={{
    border: '2px solid transparent',
    backgroundImage: 'linear-gradient(#fff, #fff), linear-gradient(-45deg, #18D0B4, #A49BFF)',
    backgroundOrigin: 'border-box',
    backgroundClip: 'content-box, border-box'
  }} top={top} bottom={bottom} ActionButton={<Button style={{
    background: 'linear-gradient(90deg, #8478FF 0%, #3DA9FF 100%)'
  }} size={'lg'} className={'w-full'} onClick={goLink}>
          <div className={'flex items-center gap-3xs text-body-lg'}>
            <BoltFillIcon fill={'#FFF'} />
            {t('home.pricing.nowInquiry', '지금 문의하기')}
          </div>
        </Button>} data-sentry-element="PricingCard" data-sentry-component="PaidPricingCard" data-sentry-source-file="PricingSection1.tsx" />;
};