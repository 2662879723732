import ReactGA from 'react-ga4';
import { EventProperties, getStoredUTMParams } from '@zep/utils/analytics';

import { zepLogger } from '../localLogger';

export const zepAnalytics: ZepAnalyticsType = {
  track: (eventName, _eventProperties) => {
    const utmParams = getStoredUTMParams();
    const eventProperties = { ..._eventProperties, ...utmParams };

    zepLogger.log(eventName, {
      optionalParams: eventProperties,
    });
    ReactGA.event(String(eventName), eventProperties);
  },
};

type ZepAnalyticsType = {
  track: (eventName: string, eventProperties?: EventProperties) => void;
};
