import { useCallback } from 'react';
import { isIgnoreError } from '@zep/api/apiUtil';
import { combinePathAndQuery } from '@zep/utils';
import { useRouter } from 'next/router';

import { clearStorageUser } from '../lib/auth';

export const useApiError = () => {
  const router = useRouter();

  const goLoginPage = useCallback(async () => {
    console.warn('token Expired');
    clearStorageUser();
    if (router.isReady) {
      await router.push(combinePathAndQuery('/login', router.query));
    }
  }, [router]);

  return (error: Error) => {
    if (isIgnoreError(error)) {
      goLoginPage();
    }
  };
};
