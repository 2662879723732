import { PropsWithChildren } from 'react';
import { SupportedLocaleMap } from '@zep/consts/localize';
import { useLocale } from '@zep/hooks';
import { Button } from '@zep/ui_v3';
import { cn } from '@zep/utils';
import { zepAnalytics } from '@zep/utils/analytics';
import Link from 'next/link';
import { Trans, useTranslation } from 'next-i18next';
import { Videos } from './Videos';
export const Cta = () => {
  return <section className={cn('flex flex-col items-center bg-gradient-to-b from-white to-gray-100', 'pt-2xl pb-lg px-[16px] min-[751px]:pt-3xl min-[751px]:pb-2xl min-[751px]:px-[24px] min-[1025px]:pb-4xl min-[1025px]:px-[40px]')} data-sentry-component="Cta" data-sentry-source-file="Cta.tsx">
      <Title data-sentry-element="Title" data-sentry-source-file="Cta.tsx" />
      <Buttons data-sentry-element="Buttons" data-sentry-source-file="Cta.tsx" />
      <Videos data-sentry-element="Videos" data-sentry-source-file="Cta.tsx" />
    </section>;
};
const Title = () => {
  const {
    t
  } = useTranslation(['landing', 'common']);
  return <h1 className="flex flex-col items-center text-center text-display-md-extrabold" data-sentry-component="Title" data-sentry-source-file="Cta.tsx">
      <p>
        <Trans t={t} i18nKey="landingV2.cta.title" components={{
        Strong: <StrongText />,
        Br: <br className="min-[751px]:hidden" />
      }} data-sentry-element="Trans" data-sentry-source-file="Cta.tsx" />
      </p>
    </h1>;
};
export const StrongText = ({
  children
}: PropsWithChildren) => <strong className="text-primary" data-sentry-component="StrongText" data-sentry-source-file="Cta.tsx">{children}</strong>;
export const INQUIRY_LINK: SupportedLocaleMap<'ko' | 'en' | 'ja'> = {
  ko: 'https://quiz.zep.us/embed/sales?webFormUrl=https%3A%2F%2Fsalesmap.kr%2Fweb-form%2Fa399a0d0-ade8-48be-840f-89b7a88f03f2&title=ZEP+QUIZ+%EB%8F%84%EC%9E%85%EC%9D%B4+%EA%B3%A0%EB%AF%BC%EC%9D%B4%EC%8B%A0%EA%B0%80%EC%9A%94%3F%0A%EC%84%A0%EC%83%9D%EB%8B%98%EC%9D%84+%EC%9C%84%ED%95%9C+%EC%B5%9C%EC%A0%81%EC%9D%98+%EC%86%94%EB%A3%A8%EC%85%98%EC%9D%84+%EC%A0%9C%EA%B3%B5%ED%95%B4+%EB%93%9C%EB%A0%A4%EC%9A%94&desc=%EC%95%84%EB%9E%98+%EC%96%91%EC%8B%9D%EC%9D%84+%EC%9E%91%EC%84%B1+%ED%9B%84+%EC%A0%9C%EC%B6%9C%ED%95%B4+%EC%A3%BC%EC%8B%9C%EB%A9%B4%0A%EB%8B%B4%EB%8B%B9+%EB%A7%A4%EB%8B%88%EC%A0%80%EA%B0%80+%EB%B9%A0%EB%A5%B8+%EC%8B%9C%EC%9D%BC+%EB%82%B4%EB%A1%9C+%EC%83%81%EB%8B%B4%EC%9D%84+%EB%8F%84%EC%99%80%EB%93%9C%EB%A6%AC%EA%B2%A0%EC%8A%B5%EB%8B%88%EB%8B%A4.',
  en: 'https://tally.so/r/wvLX7X',
  ja: 'https://tally.so/r/npWejy'
};
const Buttons = () => {
  const {
    t
  } = useTranslation(['landing', 'common']);
  const {
    locale
  } = useLocale();
  return <div className="mb-2xl mt-[30px] flex items-center gap-2xs" data-sentry-component="Buttons" data-sentry-source-file="Cta.tsx">
      <Button variant="outlined" intent="alternative" size="lg" rounded="default" asChild={true} data-sentry-element="Button" data-sentry-source-file="Cta.tsx">
        <Link href={INQUIRY_LINK[locale] ?? INQUIRY_LINK.en} target="_blank" rel="noopener noreferrer" data-sentry-element="Link" data-sentry-source-file="Cta.tsx">
          {t('landing.cta.introduceInquiry', '도입 문의')}
        </Link>
      </Button>
      <Button variant="contained" intent="strong" size="lg" rounded="default" asChild={true} data-sentry-element="Button" data-sentry-source-file="Cta.tsx">
        <Link href={'/build'} onClick={() => zepAnalytics.track('enter_create', {
        location: 'home'
      })} prefetch={false} data-sentry-element="Link" data-sentry-source-file="Cta.tsx">
          {t('landing.cta.createQuiz')}
        </Link>
      </Button>
    </div>;
};