export const logInTable = (
  message: string,
  {
    name,
    optionalParams,
  }: { name: string; optionalParams?: Record<string, unknown> },
) => {
  console.groupCollapsed(`[${name}] ${message}`);
  console.log('Parameters:');

  const _optionalParams: Record<string, unknown> = {};
  Object.entries(optionalParams ?? {}).reduce((acc, [key, value]) => {
    // console.table은 value가 object/array인 경우 column이 여러 개가 되도록 동작하는데요.
    // 이건 원하는 동작이 아니므로 하나의 column에 값을 표현하기 위해 stringify합니다.
    if (typeof value === 'object') {
      acc[key] = JSON.stringify(value);
    } else {
      acc[key] = value;
    }
    return acc;
  }, _optionalParams);
  console.table(_optionalParams);

  console.groupEnd();
};
