interface Props {
  webmSrc: string;
  mp4Src: string;
  onProgress: (progressRatio: number) => void;
  onEnd: () => void;
}
export const SelectedVideo = ({
  webmSrc,
  mp4Src,
  onProgress,
  onEnd
}: Props) => {
  return <video className="aspect-video w-full rounded-lg object-cover object-top" playsInline controls={false} autoPlay muted onTimeUpdate={e => onProgress(e.currentTarget.currentTime / e.currentTarget.duration)} onEnded={onEnd} data-sentry-component="SelectedVideo" data-sentry-source-file="SelectedVideo.tsx">
      <source type="video/webm" src={webmSrc} />
      <source type="video/mp4" src={mp4Src} />
      Your browser does not support the video tag.
    </video>;
};