import { useLocale } from '@zep/hooks';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { SSRConfig } from 'next-i18next';
import { SeoProperties } from '../../types';
import { isDevelopment, THIS_IS_DEFAULT_STATIC_OR_SERVER_SIDE_PROPS } from '../../utils';
import { RequireDefaultPageProps } from './RequireDefaultPageProps';
const getTitle = (title: string, postTitle: string) => `${title || 'ZEP QUIZ'} | ${postTitle}`;
export const Meta = ({
  pageProps
}: MetaProps) => {
  const {
    _nextI18Next,
    seoContent: partialSeoContent,
    noIndex = false
  } = pageProps;
  const {
    asPath
  } = useRouter();
  const {
    locale,
    locales
  } = useLocale();
  const defaultPostTitle = _nextI18Next?.initialI18nStore[locale]?.common['seo.default.postTitle'] ?? '나만의 젭 퀴즈를 만들어 더 재미있는 수업을 진행해 보세요';
  const title = getTitle(partialSeoContent?.title ?? '', defaultPostTitle);
  const defaultDescription = _nextI18Next?.initialI18nStore[locale]?.common['seo.default.description'] ?? '';
  const defaultImage = isDevelopment() ? 'https://dev-quiz.zep.us/assets/ogimage.png' : 'https://quiz.zep.us/assets/ogimage.png';
  const seoContent = {
    title,
    subject: partialSeoContent?.subject ?? title,
    description: partialSeoContent?.description ?? defaultDescription,
    image: partialSeoContent?.image ?? defaultImage,
    keywords: partialSeoContent?.keywords ?? 'ZEP, metaverse, zep quiz, meta, quiz, 젭, 젭퀴즈, 퀴즈 제작, 퀴즈 만들기, 방탈출퀴즈',
    copyright: partialSeoContent?.copyright ?? 'ZEP Inc',
    author: partialSeoContent?.author ?? '',
    schema: ({
      image: defaultImage,
      ...partialSeoContent?.schema
    } as Record<string, any>)
  };
  return <RequireDefaultPageProps pageProps={pageProps} data-sentry-element="RequireDefaultPageProps" data-sentry-component="Meta" data-sentry-source-file="Meta.tsx">
      <Head data-sentry-element="Head" data-sentry-source-file="Meta.tsx">
        {locale === 'ko' && !noIndex && <link rel="canonical" href={`${process.env.NEXT_PUBLIC_BASE_URL}${asPath === '/' ? '' : asPath}`} />}
        <link rel="alternate" hrefLang="x-default" href={`${process.env.NEXT_PUBLIC_BASE_URL}${asPath === '/' ? '' : asPath}`} />
        {locales.map(lang => <link key={lang} rel="alternate" hrefLang={lang} href={`${process.env.NEXT_PUBLIC_BASE_URL}${lang === 'ko' ? '' : `/${lang}`}${asPath === '/' ? '' : asPath}`} />)}
        <link rel="icon" type="image/svg+xml" href="/assets/ZEPQuiz-favicon.svg" />
        {isDevelopment() || noIndex ? <>
            <meta name="robots" content="noindex" />
            <meta name="googlebot" content="noindex" />
          </> : <>
            <meta name="robots" content="index, follow" />
            <meta name="googlebot" content="index, follow" />
          </>}
        <meta name="google" content="notranslate" data-sentry-element="meta" data-sentry-source-file="Meta.tsx" />
        <title>{seoContent.title}</title>
        <meta name="title" content={seoContent.title} data-sentry-element="meta" data-sentry-source-file="Meta.tsx" />
        <meta name="description" content={seoContent.description} data-sentry-element="meta" data-sentry-source-file="Meta.tsx" />
        <meta name="subject" content={seoContent.subject} data-sentry-element="meta" data-sentry-source-file="Meta.tsx" />
        <meta name="keywords" content={seoContent.keywords} data-sentry-element="meta" data-sentry-source-file="Meta.tsx" />
        <meta property="og:type" content="website" data-sentry-element="meta" data-sentry-source-file="Meta.tsx" />
        <meta property="og:site_name" content={seoContent.title} data-sentry-element="meta" data-sentry-source-file="Meta.tsx" />
        <meta property="og:url" content={`${process.env.NEXT_PUBLIC_BASE_URL}/${locale}${asPath === '/' ? '' : asPath}`} data-sentry-element="meta" data-sentry-source-file="Meta.tsx" />
        <meta property="og:title" content={seoContent.title} data-sentry-element="meta" data-sentry-source-file="Meta.tsx" />
        <meta property="og:description" content={seoContent.description} data-sentry-element="meta" data-sentry-source-file="Meta.tsx" />
        <meta property="og:image" content={seoContent.image} data-sentry-element="meta" data-sentry-source-file="Meta.tsx" />
        <meta property="og:locale" content={locale} data-sentry-element="meta" data-sentry-source-file="Meta.tsx" />
        <meta property="twitter:card" content="summary" data-sentry-element="meta" data-sentry-source-file="Meta.tsx" />
        <meta property="twitter:title" content={seoContent.title} data-sentry-element="meta" data-sentry-source-file="Meta.tsx" />
        <meta property="twitter:description" content={seoContent.description} data-sentry-element="meta" data-sentry-source-file="Meta.tsx" />
        <meta property="twitter:image" content={seoContent.image} data-sentry-element="meta" data-sentry-source-file="Meta.tsx" />
        <meta name="application-name" content="ZEP Quiz" data-sentry-element="meta" data-sentry-source-file="Meta.tsx" />
        <meta name="copyright" content={seoContent.copyright} data-sentry-element="meta" data-sentry-source-file="Meta.tsx" />
        <meta name="author" content={seoContent.author} data-sentry-element="meta" data-sentry-source-file="Meta.tsx" />
        <meta name="reply-to" content="hello@zep.us" data-sentry-element="meta" data-sentry-source-file="Meta.tsx" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" data-sentry-element="meta" data-sentry-source-file="Meta.tsx" />
        {seoContent.schema['@context'] && <script type="application/ld+json" dangerouslySetInnerHTML={{
        __html: JSON.stringify(seoContent.schema)
      }} />}
      </Head>
    </RequireDefaultPageProps>;
};
export type MetaProps = {
  pageProps: {
    seoContent: Partial<SeoProperties>;
    noIndex?: boolean;
    [THIS_IS_DEFAULT_STATIC_OR_SERVER_SIDE_PROPS]?: boolean;
  } & SSRConfig;
};