import { safeLocalStorage } from '@toss/storage';

import { ZettaClient } from '../analytics/zettaClient';
import { Logger } from '../logger';
import { logInTable } from '../logger/utils';

const CONSOLE_DEBUG_KEY = 'ZETTA_DEBUG';

/**
 * localStorage.setItem({CONSOLE_DEBUG_KEY}, 'true') 로 콘솔 로그를 활성화할 수 있습니다.
 */
export class ZettaLogger implements Logger {
  public name = 'ZettaLogger';
  private _zettaClient: ZettaClient;

  constructor(zettaClient: ZettaClient) {
    this._zettaClient = zettaClient;
  }

  private _debug(
    message: string,
    optionalParams: Record<string, unknown> = {},
  ) {
    logInTable(message, {
      name: this.name,
      optionalParams,
    });
  }

  log: Logger['log'] = (message, options) => {
    if (safeLocalStorage.get(CONSOLE_DEBUG_KEY) === 'true') {
      this._debug(message, options?.optionalParams);
    }

    this._zettaClient.track(message, options?.optionalParams);
  };
}
