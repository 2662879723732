import { ReactNode } from 'react';
import { useIsInGoogleClassroom } from '../hooks/useIsInGoogleClassroom';
export const Conditional = ({
  inGoogleClassroom,
  children
}: {
  inGoogleClassroom: ReactNode;
  children?: ReactNode;
}) => {
  const isInGoogleClassroom = useIsInGoogleClassroom();
  return isInGoogleClassroom ? inGoogleClassroom : children;
};