import { SupportedLocaleMap } from '@zep/consts/localize';
import { useLocale } from '@zep/hooks';
import { Trans, useTranslation } from 'next-i18next';
import { FunctionCard, FunctionCards } from './FunctionCards';
export const Teacher = () => {
  const {
    t
  } = useTranslation(['landing', 'common']);
  return <section className="px-[16px] py-2xl min-[751px]:px-[24px] min-[1025px]:px-[40px] min-[1025px]:py-3xl" data-sentry-component="Teacher" data-sentry-source-file="Teacher.tsx">
      <div className="mx-auto flex w-full max-w-[1280px] flex-col items-center gap-lg min-[751px]:items-stretch min-[751px]:gap-2xl">
        <h2 className="px-[16px] text-center text-display-sm-extrabold text-default min-[751px]:whitespace-normal min-[751px]:text-left">
          {t('landing.teacherFeatures.title')}
        </h2>
        <FunctionCards topElement={<Function1 />} bottomLeftElement={<Function2 />} bottomRightElement={<Function3 />} data-sentry-element="FunctionCards" data-sentry-source-file="Teacher.tsx" />
      </div>
    </section>;
};
const FUNCTION_1_IMAGE_LINKS: SupportedLocaleMap<'ko' | 'en' | 'ja'> = {
  ko: 'https://asset-zepetoful.zepeto.io/lZS2as4ZxDWZ/w1rqQ3wK87durTwZw7XwnUz/TEHya3wK87h974181ff6c247f697c7e5ae243699295urTwZzFWPwGO/quiz_landing_1_1_image_kr_2x.webp',
  en: 'https://asset-zepetoful.zepeto.io/lZS2as4ZxDWZ/DuNOw3wK87durTwZw43qYao/U99Fd3wK87h974181ff6c247f697c7e5ae243699295urTwZzFXgxs8/quiz_landing_1_1_image_en_2x.webp',
  ja: 'https://asset-zepetoful.zepeto.io/lZS2as4ZxDWZ/pC4zP3wK87curTwZw5pnSRk/whRFm3wK87F8bbdff842495b7a28f1d44cc925e271durTwZycHPgEp/quiz_landing_1_1_image_jp_2x.webp'
};
const Function1 = () => {
  const {
    t
  } = useTranslation(['landing', 'common']);
  const {
    locale
  } = useLocale();
  return <FunctionCard.HorizontalWrapper className="bg-purple-50" data-sentry-element="unknown" data-sentry-component="Function1" data-sentry-source-file="Teacher.tsx">
      <div className="flex-1">
        <div className="flex size-full flex-col items-center gap-sm px-lg py-xl min-[751px]:items-stretch min-[751px]:gap-lg min-[751px]:p-2xl min-[751px]:pr-0 min-[1025px]:p-3xl min-[1025px]:pr-0">
          <FunctionCard.Title className="text-purple-800" data-sentry-element="unknown" data-sentry-source-file="Teacher.tsx">
            <Trans t={t} i18nKey="landing.teacherFeature1.title1_new" components={{
            Br: <br className="hidden min-[751px]:block min-[1024px]:hidden" />
          }} data-sentry-element="Trans" data-sentry-source-file="Teacher.tsx" />
          </FunctionCard.Title>
          <FunctionCard.Description data-sentry-element="unknown" data-sentry-source-file="Teacher.tsx">
            {t('landing.teacherFeature1.content')}
          </FunctionCard.Description>
        </div>
      </div>

      <div className="flex-1 min-[751px]:h-[310px] min-[1025px]:h-[374px]">
        <img src={FUNCTION_1_IMAGE_LINKS[locale] ?? FUNCTION_1_IMAGE_LINKS.en} alt="Function 1" className="size-full min-[751px]:object-cover min-[751px]:object-left" />
      </div>
    </FunctionCard.HorizontalWrapper>;
};
const FUNCTION_2_IMAGE_LINKS: SupportedLocaleMap<'ko' | 'en' | 'ja'> = {
  ko: 'https://asset-zepetoful.zepeto.io/lZS2as4ZxDWZ/ADOzJ3wK87durTwZw5Yw1kI/LnxVk3wK87h974181ff6c247f697c7e5ae243699295urTwZzFI792b/quiz_landing_1_2_image_kr_2x.webp',
  en: 'https://asset-zepetoful.zepeto.io/lZS2as4ZxDWZ/QTJ7Q3wK87curTwZw7sGPc5/jj7iH3wK87h974181ff6c247f697c7e5ae243699295urTwZzFybkDe/quiz_landing_1_2_image_en_2x.webp',
  ja: 'https://asset-zepetoful.zepeto.io/lZS2as4ZxDWZ/C2b6T3wK87curTwZw7A0Kjo/BCxTm3wK87F8bbdff842495b7a28f1d44cc925e271durTwZydnSagd/quiz_landing_1_2_image_jp_2x.webp'
};
const Function2 = () => {
  const {
    t
  } = useTranslation(['landing', 'common']);
  const {
    locale
  } = useLocale();
  return <FunctionCard.VerticalWrapper className="bg-purple-100" data-sentry-element="unknown" data-sentry-component="Function2" data-sentry-source-file="Teacher.tsx">
      <div className="flex flex-col items-center gap-sm px-lg py-xl min-[751px]:items-stretch min-[751px]:gap-lg min-[751px]:p-2xl min-[751px]:pb-0 min-[1025px]:p-3xl min-[1025px]:pb-0">
        <FunctionCard.Title className="text-purple-900" data-sentry-element="unknown" data-sentry-source-file="Teacher.tsx">
          {t('landing.teacherFeature2.title')}
        </FunctionCard.Title>
        <FunctionCard.Description data-sentry-element="unknown" data-sentry-source-file="Teacher.tsx">
          {t('landing.teacherFeature2.content')}
        </FunctionCard.Description>
      </div>

      <img src={FUNCTION_2_IMAGE_LINKS[locale] ?? FUNCTION_2_IMAGE_LINKS.en} alt="Function 2" />
    </FunctionCard.VerticalWrapper>;
};
const Function3 = () => {
  const {
    t
  } = useTranslation(['landing', 'common']);
  return <FunctionCard.VerticalWrapper className="bg-[#F6F3FA]" data-sentry-element="unknown" data-sentry-component="Function3" data-sentry-source-file="Teacher.tsx">
      <div className="flex flex-col items-center gap-sm px-lg py-xl min-[751px]:items-stretch min-[751px]:gap-lg min-[751px]:p-2xl min-[751px]:pb-0 min-[1025px]:p-3xl min-[1025px]:pb-0">
        <FunctionCard.Title className="text-purple-900" data-sentry-element="unknown" data-sentry-source-file="Teacher.tsx">
          {t('landing.teacherFeature3.title')}
        </FunctionCard.Title>
        <FunctionCard.Description data-sentry-element="unknown" data-sentry-source-file="Teacher.tsx">
          {t('landing.teacherFeature3.content')}
        </FunctionCard.Description>
      </div>

      <img src={'https://asset-zepetoful.zepeto.io/lZS2as4ZxDWZ/XHccZ3wK87curTxdqoZM4Ya/1eqNz3wK87hdf4b68769883a6e0508f3ca3bc3eae6durTxdsmZtPHM/quiz_landing_1_3_image_kr_2x.webp'} alt="Function 3" />
    </FunctionCard.VerticalWrapper>;
};