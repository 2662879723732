import { ComponentPropsWithoutRef } from 'react';
import { cn } from '@zep/utils';
export const SectionItem = (props: ComponentPropsWithoutRef<'div'>) => {
  const {
    className,
    children,
    ..._props
  } = props;
  return <div {..._props} className={cn('min-w-[340px] max-[540px]:w-[90vw] max-[750px]:w-[540px] max-[750px]:max-w-[540px]', className)} data-sentry-component="SectionItem" data-sentry-source-file="SectionItem.tsx">
      {children}
    </div>;
};