import type { SVGProps } from 'react';
export const Refresh = (props: SVGProps<SVGSVGElement>) => <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props} data-sentry-element="svg" data-sentry-component="Refresh" data-sentry-source-file="Refresh.tsx">
    <g clipPath="url(#Refresh_svg__a)" data-sentry-element="g" data-sentry-source-file="Refresh.tsx">
      <path fill={props.fill || `currentColor`} d="M17.607 2.74a1 1 0 0 1 1.225.707l1.098 4.098a1 1 0 0 1-.707 1.225l-4.098 1.098a1 1 0 1 1-.518-1.932l1.766-.473L15 6.67c-3.072-1.774-7.105-.693-8.88 2.38-1.773 3.071-.693 7.104 2.38 8.878s7.105.694 8.88-2.379a1 1 0 0 1 1.731 1C16.785 20.58 11.53 21.987 7.5 19.66S2.063 12.078 4.389 8.05c2.326-4.03 7.582-5.438 11.61-3.112l1.374.793-.473-1.766a1 1 0 0 1 .707-1.225" data-sentry-element="path" data-sentry-source-file="Refresh.tsx" />
    </g>
    <defs data-sentry-element="defs" data-sentry-source-file="Refresh.tsx">
      <clipPath id="Refresh_svg__a" data-sentry-element="clipPath" data-sentry-source-file="Refresh.tsx">
        <path fill="#fff" d="M0 0h24v24H0z" data-sentry-element="path" data-sentry-source-file="Refresh.tsx" />
      </clipPath>
    </defs>
  </svg>;