import { SUPPORTED_LOCALES_OBJ } from '@zep/consts/localize';
import { getDefaultStaticProps } from '@zep/utils/next';
import { GetStaticPropsContext } from 'next';
import { Landing } from '../views/Landing';
export default function Home() {
  return <Landing data-sentry-element="Landing" data-sentry-component="Home" data-sentry-source-file="index.tsx" />;
}
export const getStaticProps = async ({
  locale = SUPPORTED_LOCALES_OBJ.en
}: GetStaticPropsContext) => {
  return getDefaultStaticProps({
    locale,
    namespaces: ['landing', 'common']
  });
};