import type { ErrorEvent, EventHint } from '@sentry/nextjs';

import { shouldIgnoreError } from './sentryIgnoredErrors';

export const createBeforeSend = (
  event: ErrorEvent,
  hint: EventHint,
): ErrorEvent | PromiseLike<ErrorEvent | null> | null => {
  const error = hint.originalException;
  // 클라이언트에서만 에러 필터링 적용
  if (shouldIgnoreError(error)) {
    console.error('Sentry Report Prevented Error =>', error);
    return null;
  }
  // 서버나 엣지에서는 모든 에러 보고
  return event;
};
