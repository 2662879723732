export const ApiRoute = {
  API_CATEGORIES: '/category',
  API_ADMIN_SEARCH: '/adminV2/search',
  API_ADMIN_SET_BEST: '/adminV2/edit/isSelected',
  API_ADMIN_SET_PUBLIC: '/adminV2/edit/isPublic',
  API_ADMIN_SET_CATEGORIES: '/adminV2/edit/category',
  API_SEARCH_QUIZES: '/quiz/search',
  API_MY_QUIZ: '/members/myquiz',
  API_EDIT_ME: '/members/edit',
  API_MEMBER_INFO: '/members/memberInfo',
  API_SELECT_USERTYPE: '/members/selectType',
  API_CREATE_QUIZ: '/quiz/create',
  API_EDIT_QUIZ: '/quiz/edit',
  API_DETAIL_QUIZ: '/quiz/details',
  API_UPLOAD_IMAGE: 'quiz/upload/image',
  API_QUIZ_DELETE: '/quiz/delete',
  API_CODE_ENTER: '/quiz/enterCode',
  API_ENTRY_MAP: '/quiz/entryMap',
  API_LOGIN: '/login',
  API_ISO: '/utils/iso',
  API_CHECK_MY_QUIZ: '/quiz/isAuthor',
} as const;

export const UnauthenticatedApiRoute = [ApiRoute.API_LOGIN] as string[];
