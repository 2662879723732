import { SupportedLocaleMap } from '@zep/consts/localize';
import { useLocale } from '@zep/hooks';
import { useTranslation } from 'next-i18next';
import { FunctionCard, FunctionCards } from './FunctionCards';
import { ProChip } from './ProChip';
export const Students = () => {
  const {
    t
  } = useTranslation(['landing', 'common']);
  return <section className="px-[16px] py-2xl min-[751px]:px-[24px] min-[1025px]:px-[40px] min-[1025px]:py-3xl" data-sentry-component="Students" data-sentry-source-file="Students.tsx">
      <div className="mx-auto flex w-full max-w-[1280px] flex-col items-center gap-lg min-[751px]:items-stretch min-[751px]:gap-2xl min-[1025px]:gap-3xl">
        <h2 className="px-[16px] text-center text-display-sm-extrabold text-default min-[751px]:text-left">
          {t('landing.studentFeatures.title')}
        </h2>
        <FunctionCards topElement={<Function1 />} bottomLeftElement={<Function2 />} bottomRightElement={<Function3 />} data-sentry-element="FunctionCards" data-sentry-source-file="Students.tsx" />
      </div>
    </section>;
};
const FUNCTION_1_IMAGE_LINKS: SupportedLocaleMap<'ko' | 'en' | 'ja'> = {
  ko: 'https://asset-zepetoful.zepeto.io/lZS2as4ZxDWZ/jkGn83wK87curTxdqqrHYHT/zFER23wK87hdf4b68769883a6e0508f3ca3bc3eae6durTxdsm7uoFK/quiz_landing_3_1_image_kr_2x.webp',
  en: 'https://asset-zepetoful.zepeto.io/lZS2as4ZxDWZ/7lpmY3wK87curTxdqpkvfnq/eCVF73wK87F9aa727e804757a33bd2df5bf84ec8cefurTxdsEyo1Ys/quiz_landing_3_1_image_en_2x.webp',
  ja: 'https://asset-zepetoful.zepeto.io/lZS2as4ZxDWZ/r9CFH3wK87durTxdqnpAxgH/47CGZ3wK87h26487f5912c11b0c036602d0b1b0c72eurTxdsq10MDL/quiz_landing_3_1_image_jp_2x.webp'
};
const Function1 = () => {
  const {
    t
  } = useTranslation(['landing', 'common']);
  const {
    locale
  } = useLocale();
  return <FunctionCard.HorizontalWrapper className="bg-blue-50" data-sentry-element="unknown" data-sentry-component="Function1" data-sentry-source-file="Students.tsx">
      <div className="flex-1">
        <div className="flex size-full flex-col items-center gap-sm px-lg py-xl min-[751px]:items-stretch min-[751px]:gap-lg min-[751px]:p-2xl min-[751px]:pr-0 min-[1025px]:p-3xl min-[1025px]:pr-0">
          <FunctionCard.Title className="text-blue-900" data-sentry-element="unknown" data-sentry-source-file="Students.tsx">
            {t('landing.studentFeature1.title')}
          </FunctionCard.Title>
          <FunctionCard.Description data-sentry-element="unknown" data-sentry-source-file="Students.tsx">
            {t('landing.studentFeature1.content')}
          </FunctionCard.Description>
        </div>
      </div>

      <div className="flex-1 min-[751px]:h-[310px] min-[1025px]:h-[374px]">
        <img src={FUNCTION_1_IMAGE_LINKS[locale] ?? FUNCTION_1_IMAGE_LINKS.en} alt="Function 1" className="size-full min-[751px]:object-cover min-[751px]:object-left" />
      </div>
    </FunctionCard.HorizontalWrapper>;
};
const Function2 = () => {
  const {
    t
  } = useTranslation(['landing', 'common']);
  return <FunctionCard.VerticalWrapper className="bg-blue-100" data-sentry-element="unknown" data-sentry-component="Function2" data-sentry-source-file="Students.tsx">
      <div className="flex flex-col items-center gap-sm px-lg py-xl min-[751px]:items-stretch min-[751px]:gap-lg min-[751px]:p-2xl min-[751px]:pb-0 min-[1025px]:p-3xl min-[1025px]:pb-0">
        <div className="flex flex-col items-center gap-md min-[751px]:items-stretch">
          <ProChip data-sentry-element="ProChip" data-sentry-source-file="Students.tsx" />
          <FunctionCard.Title className="text-blue-900" data-sentry-element="unknown" data-sentry-source-file="Students.tsx">
            {t('landing.studentFeature2.title')}
          </FunctionCard.Title>
        </div>
        <FunctionCard.Description data-sentry-element="unknown" data-sentry-source-file="Students.tsx">
          {t('landing.studentFeature2.content')}
        </FunctionCard.Description>
      </div>

      <img src="https://asset-zepetoful.zepeto.io/lZS2as4ZxDWZ/S47IZ3wK87curTxg3CsNGvm/YyqyC3wK87hcb439b2396fd6e5e9fff8dcae5f2820aurTxg7LJC46H/quiz_landing_3_2_image_kr_2x.webp" alt="Function 2" />
    </FunctionCard.VerticalWrapper>;
};
const FUNCTION_3_IMAGE_LINKS: SupportedLocaleMap<'ko' | 'en' | 'ja'> = {
  ko: 'https://asset-zepetoful.zepeto.io/lZS2as4ZxDWZ/DAStV3wK87durTxg3DasCSI/4Iokk3wK87hd43b37eae1bf50d15e65ffd73180c9faurTxg7LzsMPw/quiz_landing_3_3_image_kr_2x.webp',
  en: 'https://asset-zepetoful.zepeto.io/lZS2as4ZxDWZ/5eHOU3wK87durTxg3EEnh0R/z0WUs3wK87h822fa06365a9243ed15ab3f8122b58eburTxgaIqaxx5/quiz_landing_3_3_image_en_2x.webp',
  ja: 'https://asset-zepetoful.zepeto.io/lZS2as4ZxDWZ/eYo2O3wK87curTxg3BmtA0e/cPbmY3wK87h73647374a298602382efe594fbdf000durTxg7LyIrMM/quiz_landing_3_3_image_jp_2x.webp'
};
const Function3 = () => {
  const {
    t
  } = useTranslation(['landing', 'common']);
  const {
    locale
  } = useLocale();
  return <FunctionCard.VerticalWrapper className="bg-[#EEF5FA]" data-sentry-element="unknown" data-sentry-component="Function3" data-sentry-source-file="Students.tsx">
      <div className="flex flex-col items-center gap-sm px-lg py-xl min-[751px]:items-stretch min-[751px]:gap-lg min-[751px]:p-2xl min-[751px]:pb-0 min-[1025px]:p-3xl min-[1025px]:pb-0">
        <FunctionCard.Title className="text-blue-900" data-sentry-element="unknown" data-sentry-source-file="Students.tsx">
          {t('landing.studentFeature3.title')}
        </FunctionCard.Title>
        <FunctionCard.Description data-sentry-element="unknown" data-sentry-source-file="Students.tsx">
          {t('landing.studentFeature3.content')}
        </FunctionCard.Description>
      </div>

      <img src={FUNCTION_3_IMAGE_LINKS[locale] ?? FUNCTION_3_IMAGE_LINKS.en} alt="Function 3" />
    </FunctionCard.VerticalWrapper>;
};