import { SUPPORTED_LOCALES_OBJ, SupportedLocale } from '@zep/consts/localize';
import { useTranslation } from 'next-i18next';

export const useLocale = () => {
  const { i18n } = useTranslation();
  return {
    locale: (i18n.resolvedLanguage ??
      SUPPORTED_LOCALES_OBJ.en) as SupportedLocale,

    locales: (i18n.options?.supportedLngs || []).filter(
      // cimode는 국제화(i18n)를 설정하거나 디버깅할 때 유용한 특별한 언어 코드입니다. cimode를 활성화하면 실제 번역 대신 번역 키 자체를 반환합니다.
      language => language !== 'cimode',
    ) as SupportedLocale[],
  };
};
