import { ReactNode, useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useBooleanState, useIsMounted } from '@toss/react';
import { useLocale } from '@zep/hooks';
import { ChevronDownIcon, ChevronUpIcon, IconContainer } from '@zep/icons';
import { PricingProps, PricingSection } from '@zep/module/payment';
import { SectionItem } from '@zep/module/payment/Pricing/SectionItem';
import { cn } from '@zep/utils';
import { convertUrlsToLinks } from '@zep/utils/link';
import { useTranslation } from 'next-i18next';
export const PricingSection3 = (props: PricingProps) => {
  const {
    t
  } = useTranslation();
  const {
    locale
  } = useLocale();
  const questions = useMemo<{
    question: string;
    answer: string;
  }[]>(() => {
    return props.content.questions.map(d => {
      return {
        question: d.question[locale] ?? d.question.en,
        answer: d.answer.map(a => a[locale] ?? a.en).join('\n')
      };
    });
  }, [props.content, locale]);
  return <PricingSection className={cn('flex flex-col gap-2xl bg-[#F8F9FC]', 'max-[1024px]:px-xl min-[1025px]:px-3xl', 'min-[751px]:px-xl min-[891px]:px-xl', 'max-[750px]:px-md', 'py-2xl min-[751px]:py-4xl')} data-sentry-element="PricingSection" data-sentry-component="PricingSection3" data-sentry-source-file="PricingSection3.tsx">
      <div className={'font-extrabold leading-[130%] text-default max-[750px]:text-[22.5px] min-[751px]:text-[28.125px] min-[1025px]:text-heading-xl'}>
        {t('home.pricing.frequentlyQuestion', '자주 묻는 질문')}
      </div>
      <div className={'flex w-full max-w-[1000px] flex-col items-center  gap-md'}>
        {questions.map((q, index) => <QuestionCard key={index} question={q.question} answer={q.answer} />)}
      </div>
    </PricingSection>;
};
const QuestionCard = (props: {
  question: ReactNode;
  answer: string;
}) => {
  const [isShow, show, hide] = useBooleanState(false);
  const isMobile = useMediaQuery({
    query: `(min-width: 0px) and (max-width:750px)`
  });
  const isMounted = useIsMounted();
  const iconSize = isMobile && isMounted ? 18 : 24;
  const handleToggle = () => {
    isShow ? hide() : show();
  };
  return <SectionItem className={cn('nav-link flex w-full flex-col gap-sm rounded-[16px] bg-white ', 'min-[751px]:px-xl min-[751px]:py-lg max-[750px]:px-lg max-[750px]:py-md', 'min-[751px]:min-h-[82px] max-[750px]:min-h-[56px]', 'border-[#E9EAF2] border bg-[#FFF] shadow-[0px_2px_6px_0px_rgba(28,27,31,0.04)]')} data-sentry-element="SectionItem" data-sentry-component="QuestionCard" data-sentry-source-file="PricingSection3.tsx">
      <div className={'flex h-full items-start justify-between gap-sm'}>
        <div className={'flex w-full cursor-pointer items-center gap-sm '} onClick={handleToggle}>
          <QuestionIcon data-sentry-element="QuestionIcon" data-sentry-source-file="PricingSection3.tsx" />
          <span className={cn('flex size-full items-center  font-bold text-default whitespace-normal break-all', 'text-[16.875px] max-[750px]:text-[15px] !break-normal')}>
            {props.question}
          </span>
        </div>

        <IconContainer onClick={handleToggle} hitSlop={0} className={cn('min-size-[34px] rounded-[17px] bg-gray-100 p-[5px]', 'max-[750px]:min-size-[24px] max-[750px]:p-[3px]')} data-sentry-element="IconContainer" data-sentry-source-file="PricingSection3.tsx">
          {!isShow ? <ChevronDownIcon width={iconSize} height={iconSize} fill="#9E9FAF" /> : <ChevronUpIcon width={iconSize} height={iconSize} fill="#9E9FAF" />}
        </IconContainer>
      </div>
      {isShow && <div className={cn('pl-[46px] text-body-lg font-medium text-neutral', 'max-[750px]:pl-[36px] max-[750px]:text-body-sm')}>
          <div dangerouslySetInnerHTML={{
        __html: convertUrlsToLinks(props.answer)
      }} />
        </div>}
    </SectionItem>;
};
const QuestionIcon = () => {
  return <div className={cn('flex items-center justify-center rounded-[17px] bg-[#F3F2FF] font-bold text-strong', 'max-[750px]:size-[28px] max-[750px]:min-size-[28px] size-[34px]', 'max-[750px]:text-[15px] text-[20px]', 'max-[750px]:px-[9px] px-[10px]')} data-sentry-component="QuestionIcon" data-sentry-source-file="PricingSection3.tsx">
      Q
    </div>;
};